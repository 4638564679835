import React, { useRef } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Container,
  Divider,
  useTheme,
  useMediaQuery,
  Link as MuiLink,
  Alert,
} from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useReactToPrint } from "react-to-print";
import SetDocumentMeta from "../common/SetDocumentMeta";
import { kthStaffProfileConfigs } from "./kthStaffProfileConfigs";

const KthProfile = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    secColor,
    secHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;
  SetDocumentMeta({
    title: "Profile | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { id } = useParams(); // Get the profile id from the route params
  const profileConfig = kthStaffProfileConfigs[id]; // Get the profile config based on the id
  const { headerTextColor } = baseConfig.siteBranding;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // This will be true if the screen width is less than 600px
  const paperRef = useRef(); // Ref for the Paper component

  const handlePrint = useReactToPrint({
    content: () => paperRef.current,
    documentTitle: profileConfig
      ? `${profileConfig.name} - Professional Biography`
      : "Professional Biography",
    removeAfterPrint: true,
  });

  const downloadPDF = async () => {
    if (!profileConfig) return;
    const canvas = await html2canvas(paperRef.current);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [canvas.width, canvas.height],
    });
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save(
      `${profileConfig.name
        .toLowerCase()
        .replace(" ", "-")}-professional-bio.pdf`
    );
  };

  if (!profileConfig) {
    return (
      <Container className="link-no-decoration" sx={{ mt: 2 }}>
        <div ref={paperRef} id="paperContent">
          <Alert severity="warning">
            Staff profile not found or no longer on the team.{" "}
            <MuiLink component={RouterLink} to="/professionals">
              Browse staff
            </MuiLink>
            .
          </Alert>
        </div>
      </Container>
    );
  }

  const {
    name,
    avatar,
    summary,
    introParagraphs,
    professionalRecognition,
    relatedEmployment,
    experience,
    areasOfFocus,
    barAdmissions,
    education,
    skills,
    languages,
    news,
  } = profileConfig;

  const printStyles = `
  @media print {
    body * {
      visibility: hidden;
    }
    #paperContent, #paperContent * {
      visibility: visible;
    }
    #paperContent {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

  const styles = {
    title: {
      fontWeight: "bold",
      backgroundColor: "#FFF",
      color: theme.palette.text.secondary,
      textAlign: "center",
      flexBasis: "100%",
    },
    bioHeader: (isMobile) => ({
      backgroundColor: "#FFF",
      padding: "20px",
      position: "relative",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "center" : "stretch",
      textAlign: isMobile ? "center" : "inherit",
    }),
    avatarSection: {
      flex: "30%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "20px",
    },
    avatar: {
      width: isMobile ? "150px" : "200px",
      height: isMobile ? "150px" : "200px",
      margin: "0 auto",
    },
    dividerSection: {
      flex: "2%",
      display: isMobile ? "none" : "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
    },
    summarySection: {
      flex: "68%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
    },
    horizontalDivider: {
      borderBottom: "2px solid",
      my: 2,
      color: headerTextColor,
    },
    profileDetails: {
      padding: "10px 20px",
      backgroundColor: "#FFF",
    },
    links: {
      display: "flex",
      justifyContent: "left",
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      color: "gray",
      backgroundColor: "#FFF",
    },
  };

  return (
    <>
      <style>{printStyles}</style>
      <Container className="link-no-decoration" sx={{ mt: 2 }}>
        <div ref={paperRef} id="paperContent">
          <Paper elevation={3} sx={{ backgroundColor: "#FFF" }}>
            {/* Header Bar */}
            <Box sx={{ paddingTop: 2, backgroundColor: "#FFF" }}>
              <Typography variant="h5" sx={styles.title}>
                <span style={{ color: tertColor }}>Professional Biography</span>{" "}
                | <span style={{ color: tertColor }}>{name}</span>
              </Typography>
            </Box>
            {/* Header Section */}
            <Box sx={styles.bioHeader(isMobile)}>
              <Box sx={styles.avatarSection}>
                <Avatar alt={name} src={avatar} sx={styles.avatar} />
              </Box>

              <Box sx={styles.dividerSection}>
                <Divider
                  sx={{
                    border: "1px solid",
                    borderColor: "#9099A2",
                    alignSelf: "stretch",
                  }}
                  orientation="vertical"
                />
              </Box>

              <Box sx={styles.summarySection}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: headerTextColor }}
                >
                  {name.toUpperCase()}
                </Typography>
                {summary.map((item, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    • {item}
                  </Typography>
                ))}
                <Divider sx={styles.horizontalDivider} />

                <Box sx={styles.links}>
                  <MuiLink component={RouterLink} to="/contact">
                    CONTACT
                  </MuiLink>{" "}
                  {" | "}
                  <MuiLink href="#" onClick={downloadPDF}>
                    PDF
                  </MuiLink>
                  {" | "}
                  <MuiLink href="#" onClick={handlePrint}>
                    PRINT
                  </MuiLink>
                </Box>
              </Box>
            </Box>

            <Box sx={styles.profileDetails}>
              {introParagraphs &&
                introParagraphs.map((paragraph, index) => (
                  <Typography paragraph key={index}>
                    {paragraph}
                  </Typography>
                ))}

              {professionalRecognition && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    PROFESSIONAL RECOGNITION
                  </Typography>
                  <ul>
                    {professionalRecognition.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </>
              )}

              {relatedEmployment && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    RELATED EMPLOYMENT
                  </Typography>
                  <ul>
                    {relatedEmployment.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </>
              )}

              {experience && experience.length > 0 && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    EXPERIENCE
                  </Typography>
                  <Box sx={{ ml: 2 }}>
                    {experience.map((exp, index) => (
                      <Box key={index}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ color: secColor }}
                        >
                          {exp.title}
                        </Typography>
                        <Typography paragraph>{exp.description}</Typography>
                      </Box>
                    ))}
                  </Box>
                </>
              )}

              {areasOfFocus && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    AREAS OF FOCUS
                  </Typography>
                  <Box sx={{ ml: 2 }}>
                    {areasOfFocus.practices && (
                      <>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ color: secColor }}
                        >
                          Practices
                        </Typography>
                        <ul>
                          {areasOfFocus.practices.map((practice, index) => (
                            <li key={index}>{practice}</li>
                          ))}
                        </ul>
                      </>
                    )}
                    {areasOfFocus.industries && (
                      <>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ color: secColor }}
                        >
                          Industries
                        </Typography>
                        <ul>
                          {areasOfFocus.industries.map((industry, index) => (
                            <li key={index}>{industry}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </Box>
                </>
              )}

              {barAdmissions && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    BAR AND COURT ADMISSIONS
                  </Typography>
                  <ul>
                    {barAdmissions.map((admission, index) => (
                      <li key={index}>{admission}</li>
                    ))}
                  </ul>
                </>
              )}

              {education && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    EDUCATION
                  </Typography>
                  <ul>
                    {education.map((edu, index) => (
                      <li key={index}>{edu}</li>
                    ))}
                  </ul>
                </>
              )}

              {skills && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    SKILLS & CERTIFICATIONS
                  </Typography>
                  <ul>
                    {skills.lics && (
                      <li>
                        Technical Licenses:
                        <ul>
                          {skills.lics.map((lic, index) => (
                            <li key={index}>{lic}</li>
                          ))}
                        </ul>
                      </li>
                    )}
                    {skills.certs && (
                      <li>
                        Key Certifications:
                        <ul>
                          {skills.certs.map((cert, index) => (
                            <li key={index}>{cert}</li>
                          ))}
                        </ul>
                      </li>
                    )}
                    {skills.appDevelopment && (
                      <li>
                        Experienced in App/Web Development including:
                        <ul>
                          {skills.appDevelopment.map((skill, index) => (
                            <li key={index}>{skill}</li>
                          ))}
                        </ul>
                      </li>
                    )}
                    {skills.otherProgramming && (
                      <li>
                        Other Programming:
                        <ul>
                          {skills.otherProgramming.map((prog, index) => (
                            <li key={index}>{prog}</li>
                          ))}
                        </ul>
                      </li>
                    )}
                    {skills.aiTools && <li>{skills.aiTools}</li>}
                  </ul>
                </>
              )}

              {languages && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    LANGUAGES
                  </Typography>
                  <ul>
                    {languages.map((language, index) => (
                      <li key={index}>{language}</li>
                    ))}
                  </ul>
                </>
              )}

              {news && news.length > 0 && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: headerTextColor }}
                  >
                    NEWS
                  </Typography>
                  <Box sx={{ ml: 2 }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: "gray", fontStyle: "italic" }}
                    >
                      Press Releases
                    </Typography>
                    {news.map((newsItem, index) => (
                      <Box key={index}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{ color: tertColor, fontWeight: "bold" }}
                        >
                          {newsItem.title}
                        </Typography>
                        <Typography paragraph>
                          {newsItem.description}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </div>
      </Container>
    </>
  );
};

export default KthProfile;
