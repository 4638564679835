import React, { useEffect, useState, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Container,
  TextField,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
  Box,
  Button,
  Pagination,
  Chip,
  InputAdornment,
  Grid,
  Divider,
  useTheme,
  Paper,
  FormControl,
  InputLabel,
  IconButton,
  useMediaQuery,
  Alert,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;
const ITEMS_PER_PAGE = 10;

// Helper function to get importance rank
const getImportanceRank = (importance) => {
  const ranks = {
    Featured: 1,
    Important: 2,
    Standard: 3,
    Low: 4,
  };
  return ranks[importance] || 5; // Default to lowest rank if not found
};

const AiDefinitionList = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "AI Legal Definitions | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { headerTextColor } = baseConfig.siteBranding;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [allDefinitions, setAllDefinitions] = useState([]);
  const [filteredDefinitions, setFilteredDefinitions] = useState([]);
  const [displayedDefinitions, setDisplayedDefinitions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [sourceJurisdiction, setSourceJurisdiction] = useState("");
  const [sourceTypeOptions, setSourceTypeOptions] = useState([]);
  const [sourceJurisdictionOptions, setSourceJurisdictionOptions] = useState(
    []
  );
  const [selectedLetter, setSelectedLetter] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get all letters that have at least one definition
  const availableLetters = useMemo(() => {
    if (!allDefinitions.length) return [];

    const letters = new Set();
    allDefinitions.forEach((def) => {
      if (def.term && def.term.length > 0) {
        letters.add(def.term[0].toUpperCase());
      }
    });

    return Array.from(letters).sort();
  }, [allDefinitions]);

  // Fetch both filters and definitions data
  // Check for URL parameters on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sourceTypeParam = searchParams.get("source_type");
    const sourceJurisdictionParam = searchParams.get("source_jurisdiction");
    const letterParam = searchParams.get("letter");
    const searchParam = searchParams.get("search");

    if (sourceTypeParam) setSourceType(sourceTypeParam);
    if (sourceJurisdictionParam) setSourceJurisdiction(sourceJurisdictionParam);
    if (letterParam) setSelectedLetter(letterParam.toUpperCase());
    if (searchParam) setSearchTerm(searchParam);
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        // Fetch filter options
        const filtersResponse = await axios.get(
          `${apiUrlKth}/kth-ai/definition-filters`
        );
        setSourceTypeOptions(filtersResponse.data.source_types || []);
        setSourceJurisdictionOptions(
          filtersResponse.data.source_jurisdictions || []
        );

        // Fetch all definitions with publication status filter
        const definitionsResponse = await axios.get(
          `${apiUrlKth}/kth-ai/definitions`,
          {
            params: {
              publication_status__in: [
                "Published",
                "Published-External-Site",
                "Published-Pending-Revision",
              ],
            },
          }
        );

        // Sort definitions by importance
        const sortedDefinitions = definitionsResponse.data.sort((a, b) => {
          return (
            getImportanceRank(a.importance) - getImportanceRank(b.importance)
          );
        });

        setAllDefinitions(sortedDefinitions);
        setFilteredDefinitions(sortedDefinitions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load definitions. Please try again later.");
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // Apply filters to definitions and update filteredDefinitions
  const applyFilters = () => {
    let filtered = [...allDefinitions];

    // Apply letter filter
    if (selectedLetter) {
      filtered = filtered.filter(
        (def) => def.term && def.term.charAt(0).toUpperCase() === selectedLetter
      );
    }

    // Apply search filter (search in term or term_blurb)
    if (searchTerm.trim()) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (def) =>
          (def.term && def.term.toLowerCase().includes(searchLower)) ||
          (def.term_blurb && def.term_blurb.toLowerCase().includes(searchLower))
      );
    }

    // Apply source type filter
    if (sourceType) {
      filtered = filtered.filter(
        (def) =>
          def.source_types &&
          Array.isArray(def.source_types) &&
          def.source_types.includes(sourceType)
      );
    }

    // Apply jurisdiction filter
    if (sourceJurisdiction) {
      filtered = filtered.filter(
        (def) =>
          def.source_jurisdictions &&
          Array.isArray(def.source_jurisdictions) &&
          def.source_jurisdictions.includes(sourceJurisdiction)
      );
    }

    // Sort by importance
    filtered.sort(
      (a, b) =>
        getImportanceRank(a.importance) - getImportanceRank(b.importance)
    );

    setFilteredDefinitions(filtered);
    setPage(1); // Reset to first page
  };

  // Update displayed definitions whenever filtered definitions or page changes
  useEffect(() => {
    const start = (page - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    setDisplayedDefinitions(filteredDefinitions.slice(start, end));
  }, [filteredDefinitions, page]);

  // Apply filters when any filter criteria changes
  useEffect(() => {
    applyFilters();
  }, [selectedLetter]);

  // Apply initial filters once data is loaded
  useEffect(() => {
    if (!loading && allDefinitions.length > 0) {
      applyFilters();
    }
  }, [loading, sourceType, sourceJurisdiction, searchTerm]);

  // Event handlers
  const handleSearch = () => applyFilters();
  const handleClearSearch = () => {
    setSearchTerm("");
    applyFilters();
  };
  const handleLetterClick = (letter) => {
    setSelectedLetter(letter === selectedLetter ? "" : letter);
  };
  const handlePageChange = (event, value) => setPage(value);
  const handleApplyFilters = () => applyFilters();
  const handleClearAllFilters = () => {
    setSearchTerm("");
    setSourceType("");
    setSourceJurisdiction("");
    setSelectedLetter("");
    setFilteredDefinitions([...allDefinitions]);
    setPage(1);
  };

  // Get importance chip color
  const getImportanceChipColor = (importance) => {
    switch (importance) {
      case "Featured":
        return "error";
      case "Important":
        return "warning";
      case "Standard":
        return "success";
      case "Low":
        return "default";
      default:
        return "default";
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 5 }}
        >
          <CircularProgress size={24} sx={{ mr: 2 }} />
          <Typography variant="h5">Loading AI Legal Definitions...</Typography>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography
          variant="h5"
          color="error"
          sx={{ textAlign: "center", my: 5 }}
        >
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 2,
          // backgroundColor: theme.palette.primary.light,
          // color: theme.palette.primary.contrastText,
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Legal Definitions for AI-Related Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
          Explore legal definitions of AI-related terms including common
          understanding of the term in industry as well definitions found in
          proposed bills, standards, regulations, statutes, court opinions,
          treaties, and more.
        </Typography>
      </Paper>

      {/* Search and filters section */}
      <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Grid container spacing={3}>
          {/* Search field */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search by term or description"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSearch()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Source Type dropdown */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Legal Source Type</InputLabel>
              <Select
                value={sourceType}
                onChange={(e) => setSourceType(e.target.value)}
                label="Legal Source Type"
              >
                <MenuItem value="">All Source Types</MenuItem>
                {sourceTypeOptions.map((type, idx) => (
                  <MenuItem key={idx} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Jurisdiction dropdown */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Jurisdiction</InputLabel>
              <Select
                value={sourceJurisdiction}
                onChange={(e) => setSourceJurisdiction(e.target.value)}
                label="Jurisdiction"
              >
                <MenuItem value="">All Jurisdictions</MenuItem>
                {sourceJurisdictionOptions.map((jurisdiction, idx) => (
                  <MenuItem key={idx} value={jurisdiction}>
                    {jurisdiction}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Filter buttons */}
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltIcon />}
              onClick={handleApplyFilters}
              sx={{ mr: 1 }}
            >
              Apply Filters
            </Button> */}
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<FilterAltOffIcon />}
              onClick={handleClearAllFilters}
            >
              Clear All Filters
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Alphabet filter */}
      <Paper elevation={1} sx={{ p: 2, mb: 4, borderRadius: 2 }}>
        <Box display="flex" justifyContent="center" flexWrap="wrap" gap={0.5}>
          {availableLetters.map((letter) => (
            <Button
              key={letter}
              variant={selectedLetter === letter ? "contained" : "outlined"}
              color={selectedLetter === letter ? "primary" : "inherit"}
              onClick={() => handleLetterClick(letter)}
              sx={{
                minWidth: "36px",
                height: "36px",
                p: 0,
                m: 0.5,
                borderRadius: 1,
              }}
            >
              {letter}
            </Button>
          ))}
        </Box>
      </Paper>

      {/* Results count and filter info */}
      {selectedLetter || searchTerm || sourceType || sourceJurisdiction ? (
        <Alert
          severity="info"
          sx={{ mb: 4 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={handleClearAllFilters}
            >
              Show All
            </Button>
          }
        >
          Showing results for
          {selectedLetter && ` letter "${selectedLetter}"`}
          {searchTerm && selectedLetter && " & "}
          {searchTerm && ` search keywords = "${searchTerm}"`}
          {(selectedLetter || searchTerm) && sourceType && " & "}
          {sourceType && ` source type = ${sourceType}`}
          {(selectedLetter || searchTerm || sourceType) &&
            sourceJurisdiction &&
            " & "}
          {sourceJurisdiction && ` jurisdiction = ${sourceJurisdiction}`}
        </Alert>
      ) : (
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontWeight: "medium" }}
          >
            {filteredDefinitions.length}{" "}
            {filteredDefinitions.length === 1 ? "result" : "results"} found
          </Typography>
        </Box>
      )}

      {/* Results listing */}
      {displayedDefinitions.length > 0 ? (
        <Grid container spacing={2}>
          {displayedDefinitions.map((definition) => (
            <Grid item xs={12} key={definition.slug}>
              <Card
                elevation={2}
                sx={{
                  transition:
                    "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: 4,
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {definition.term}
                  </Typography>

                  {/* Show source type and jurisdiction chips if available */}
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}
                  >
                    {definition.source_types &&
                      definition.source_types.length > 0 &&
                      definition.source_types.map((type, idx) => (
                        <Chip
                          key={`type-${idx}`}
                          size="small"
                          label={type}
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            setSourceType(type);
                            applyFilters();
                          }}
                          sx={{ cursor: "pointer" }}
                        />
                      ))}
                    {definition.source_jurisdictions &&
                      definition.source_jurisdictions.length > 0 &&
                      definition.source_jurisdictions.map(
                        (jurisdiction, idx) => (
                          <Chip
                            key={`jurisdiction-${idx}`}
                            size="small"
                            label={jurisdiction}
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                              setSourceJurisdiction(jurisdiction);
                              applyFilters();
                            }}
                            sx={{ cursor: "pointer" }}
                          />
                        )
                      )}
                  </Box>

                  <Typography variant="body1" color="text.secondary" paragraph>
                    {definition.term_blurb}
                  </Typography>

                  <Button
                    variant="text"
                    component={RouterLink}
                    to={`/legal-definitions-for-ai/${definition.slug}`}
                    color="primary"
                    size="small"
                    endIcon={<ChevronRightIcon />}
                    sx={{ mt: 1, textTransform: "none" }}
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Paper sx={{ p: 3, textAlign: "center", borderRadius: 2 }}>
          <Typography variant="h6" color="text.secondary">
            No definitions found matching your criteria.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearAllFilters}
            sx={{ mt: 2 }}
          >
            Clear Filters
          </Button>
        </Paper>
      )}

      {/* Pagination */}
      {filteredDefinitions.length > ITEMS_PER_PAGE && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Pagination
            count={Math.ceil(filteredDefinitions.length / ITEMS_PER_PAGE)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size={isMobile ? "small" : "medium"}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Container>
  );
};

export default AiDefinitionList;
