import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link as MuiLink,
  CircularProgress,
  Divider,
  Box,
  Button,
  Breadcrumbs,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faDollarSign,
  faClipboardList,
  faGavel,
  faLink,
  faToggleOn,
  faFileAlt,
  faListCheck,
  faOption,
  faFileContract,
  faMicrochipAi,
  faCalendarAlt,
} from "@fortawesome/pro-solid-svg-icons";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import parse from "html-react-parser";
import moment from "moment";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const AiServiceDetail = () => {
  const { slug } = useParams();
  const [service, setService] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchServiceDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-ai/services?service=${slug}`
        );
        setService(response.data);
      } catch (err) {
        setError("Failed to fetch service detail.");
        console.error("Error fetching service detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServiceDetail();
  }, [slug]);

  const formatDate = (date) => moment(date).format("MMM D, YYYY");

  if (isLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  if (error)
    return (
      <Container className="link-no-decoration">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  if (!service)
    return (
      <Container className="link-no-decoration">
        <Typography>No service data available.</Typography>
      </Container>
    );

  return (
    <Container className="link-no-decoration">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* Breadcrumbs Navigation */}
          <Breadcrumbs
            separator={<ChevronRightIcon fontSize="small" />}
            sx={{ mt: 3, mb: 3 }}
          >
            <MuiLink
              component={RouterLink}
              to="/ai"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
              AI Services Home
            </MuiLink>
            <MuiLink
              component={RouterLink}
              to="/patent-ai-resources"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <LibraryBooksIcon sx={{ mr: 0.5 }} fontSize="small" />
              AI Service Search
            </MuiLink>
            <Typography
              color="text.primary"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <DescriptionIcon sx={{ mr: 0.5 }} fontSize="small" />
              {service.name}
            </Typography>
          </Breadcrumbs>
        </Grid>

        {/* Service Name */}
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography variant="h4" gutterBottom>
            {service.name}
          </Typography>
        </Grid>

        {/* Service Overview Section */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                <FontAwesomeIcon
                  icon={faListCheck}
                  style={{ marginRight: 12 }}
                />
                Service Overview
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {service.provider && (
                  <Grid item xs={12}>
                    <Typography>
                      <FontAwesomeIcon
                        icon={faBuilding}
                        style={{ marginRight: 8 }}
                      />
                      Provider: {service.provider}
                    </Typography>
                  </Grid>
                )}
                {service.service_type && (
                  <Grid item xs={12}>
                    <Typography>
                      <FontAwesomeIcon
                        icon={faMicrochipAi}
                        style={{ marginRight: 8 }}
                      />
                      <MuiLink
                        component={RouterLink}
                        to={`/patent-ai-resources#${service.service_type.slug}`}
                      >
                        {service.service_type.name}
                      </MuiLink>
                    </Typography>
                  </Grid>
                )}
                {service.description && (
                  <Grid item xs={12}>
                    <Typography>{parse(service.description)}</Typography>
                  </Grid>
                )}
                {service.service_link && (
                  <Grid item xs={12}>
                    <MuiLink
                      href={service.service_link}
                      target="_blank"
                      rel="noopener"
                    >
                      Visit {service.name}
                    </MuiLink>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Partition between sections */}
        <Divider sx={{ mt: 3, mb: 3 }} />

        {/* Service Variants Section */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                <FontAwesomeIcon icon={faOption} style={{ marginRight: 12 }} />
                Service Variants
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {service.service_variants.map((variant, index) => (
                  <Grid item xs={12} key={index}>
                    {variant.name && (
                      <Typography variant="h6">{variant.name}</Typography>
                    )}

                    {variant.ai_model && (
                      <Typography sx={{ ml: 2, mb: 1 }}>
                        <FontAwesomeIcon
                          icon={faMicrochipAi}
                          style={{ marginRight: 8 }}
                        />
                        Model:{" "}
                        <MuiLink
                          component={RouterLink}
                          to={`/ai-models/${variant.ai_model.slug}`}
                        >
                          {variant.ai_model.title}
                        </MuiLink>
                      </Typography>
                    )}

                    {/* Subscription information */}
                    {variant.ai_subscription ? (
                      <Typography sx={{ ml: 2, mb: 1 }}>
                        <FontAwesomeIcon
                          icon={faDollarSign}
                          style={{ marginRight: 8 }}
                        />
                        <Typography
                          component="span"
                          sx={{ fontWeight: "medium" }}
                        >
                          {variant.ai_subscription}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontSize: "0.85rem" }}
                        >
                          {" "}
                          (Subscriptions for the AI Model used in this service)
                        </Typography>
                      </Typography>
                    ) : (
                      variant.ai_model &&
                      variant.ai_model.subscriptions &&
                      variant.ai_model.subscriptions.length > 0 && (
                        <Typography sx={{ ml: 2, mb: 1 }}>
                          <FontAwesomeIcon
                            icon={faDollarSign}
                            style={{ marginRight: 8 }}
                          />

                          <Typography
                            component="span"
                            sx={{ fontWeight: "medium" }}
                          >
                            {variant.ai_model.subscriptions.join(", ")}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: "0.85rem" }}
                          >
                            {" "}
                            (Subscriptions for the underlying AI Model)
                          </Typography>
                        </Typography>
                      )
                    )}

                    {/* Released date information */}
                    {variant.released ? (
                      <Typography sx={{ ml: 2, mb: 1 }}>
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          style={{ marginRight: 8 }}
                        />

                        <Typography
                          component="span"
                          sx={{ fontWeight: "medium" }}
                        >
                          {formatDate(variant.released)}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontSize: "0.85rem" }}
                        >
                          {" "}
                          (Release date of the AI model in this service)
                        </Typography>
                      </Typography>
                    ) : (
                      variant.ai_model &&
                      variant.ai_model.release_date && (
                        <Typography sx={{ ml: 2, mb: 1 }}>
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            style={{ marginRight: 8 }}
                          />

                          <Typography
                            component="span"
                            sx={{ fontWeight: "medium" }}
                          >
                            {formatDate(variant.ai_model.release_date)}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: "0.85rem" }}
                          >
                            {" "}
                            (Release date for the underlying AI Model)
                          </Typography>
                        </Typography>
                      )
                    )}

                    {variant.description && (
                      <Typography sx={{ ml: 2, mb: 2, mt: 2 }}>
                        {variant.description}
                      </Typography>
                    )}

                    <Divider sx={{ mt: 2, mb: 2 }} />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Divider sx={{ mt: 3, mb: 3 }} />

        {/* Service Compliance Section */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                <FontAwesomeIcon icon={faGavel} style={{ marginRight: 12 }} />
                Service Compliance
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {service.compliance_for_service.map((compliance, index) => (
                  <Grid item xs={12} key={index}>
                    {compliance.compliance && (
                      <Typography
                        component={RouterLink}
                        to={`/legal-compliance-for-ai/${compliance.compliance}`}
                      >
                        {compliance.compliance_name}
                      </Typography>
                    )}
                    {compliance.compliance_notes && (
                      <Typography>
                        Notes: {compliance.compliance_notes}
                      </Typography>
                    )}
                    {compliance.link_to_providers_compliance_page && (
                      <Typography sx={{ mt: 1 }}>
                        <MuiLink
                          href={compliance.link_to_providers_compliance_page}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faLink}
                            style={{ marginRight: 8 }}
                          />
                          Provider's Compliance Documentation
                        </MuiLink>
                      </Typography>
                    )}
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Divider sx={{ mt: 3, mb: 3 }} />

        {/* Service Legal Terms Section */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                <FontAwesomeIcon
                  icon={faFileContract}
                  style={{ marginRight: 12 }}
                />
                Service Legal Terms
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {service.eula_terms_for_service.map((eulaTerm, index) => (
                  <Grid item xs={12} key={index}>
                    {eulaTerm.eula_term_short && (
                      <Typography variant="h6" gutterBottom sx={{ ml: 2 }}>
                        {eulaTerm.eula_term_short}:
                      </Typography>
                    )}
                    {eulaTerm.eula_term && (
                      <Typography variant="body1" sx={{ ml: 4 }}>
                        <b>
                          {eulaTerm.eula_term} {eulaTerm.eula_response}
                        </b>
                      </Typography>
                    )}
                    {eulaTerm.eula_response_qualify && (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{ ml: 4 }}
                      >
                        ({eulaTerm.eula_response_qualify})
                      </Typography>
                    )}
                    {eulaTerm.eula && (
                      <>
                        <Typography sx={{ mt: 2, ml: 4 }}>
                          <strong>
                            {eulaTerm.eula.eula_type
                              ? `${eulaTerm.eula.eula_type} applies to:`
                              : "Legal term applies to:"}
                          </strong>{" "}
                          {eulaTerm.eula.applies_to_variant.length > 0 &&
                            eulaTerm.eula.applies_to_variant.join(", ") +
                              " variants"}
                          {eulaTerm.eula.applies_to_variant.length > 0 &&
                            eulaTerm.eula.applies_to_party.length > 0 &&
                            " | "}
                          {eulaTerm.eula.applies_to_party.length > 0 &&
                            eulaTerm.eula.applies_to_party.join(", ") +
                              " users"}
                        </Typography>

                        <Typography sx={{ mb: 1, ml: 4 }}>
                          {eulaTerm.eula.title && (
                            <>
                              <i>See</i>{" "}
                              {eulaTerm.eula.eula_link ? (
                                <a
                                  href={eulaTerm.eula.eula_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {eulaTerm.eula.title}
                                </a>
                              ) : (
                                eulaTerm.eula.title
                              )}
                              {eulaTerm.eula_cite &&
                                ` at ${eulaTerm.eula_cite}`}
                              {eulaTerm.eula.version_date && (
                                <>
                                  {" "}
                                  (version date:{" "}
                                  {formatDate(eulaTerm.eula.version_date)})
                                </>
                              )}
                              .
                            </>
                          )}
                        </Typography>

                        {eulaTerm.eula.eula_summary && (
                          <>
                            <Typography sx={{ ml: 4 }}>
                              <strong>Summary:</strong>
                            </Typography>
                            <Typography sx={{ mb: 2, ml: 4 }}>
                              {eulaTerm.eula.eula_summary}
                            </Typography>
                          </>
                        )}
                        {eulaTerm.eula.eula_discussion && (
                          <>
                            <Typography sx={{ ml: 4 }}>
                              <strong>Discussion:</strong>
                            </Typography>
                            <Typography sx={{ ml: 6 }}>
                              {parse(eulaTerm.eula.eula_discussion)}
                            </Typography>
                          </>
                        )}
                      </>
                    )}
                    {eulaTerm.eula_excerpt && (
                      <>
                        <Typography sx={{ ml: 4 }}>
                          <strong>Legal Agreement Excerpt:</strong>
                        </Typography>
                        <Typography sx={{ ml: 6 }}>
                          {parse(eulaTerm.eula_excerpt)}
                        </Typography>
                      </>
                    )}
                    {eulaTerm.discussion && (
                      <>
                        <Typography sx={{ ml: 4 }}>
                          <strong>Legal Agreement Analysis:</strong>
                        </Typography>
                        <Typography sx={{ ml: 6 }}>
                          {parse(eulaTerm.discussion)}
                        </Typography>
                      </>
                    )}
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Button
            startIcon={<FirstPageIcon />}
            color="primary"
            component={RouterLink}
            to="/patent-ai-resources"
            sx={{ mt: 2, mb: 1 }}
          >
            Return to AI Service Search
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AiServiceDetail;
