import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CircularProgress,
  Alert,
  AlertTitle,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Paper,
  Box,
  Divider,
  List,
  ListItem,
  Container,
  Link as MuiLink,
  Breadcrumbs,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import LaunchIcon from "@mui/icons-material/Launch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const KthSoftLicDetail = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Open Source Software License Detail | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { tertHoverColor, tertColor } = baseConfig.siteBranding;
  const [license, setLicense] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchLicenseDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-ip/${slug}/license-detail`
        );
        setLicense(response.data);
      } catch (err) {
        setError("Failed to fetch license detail.");
        console.error("Error fetching license detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLicenseDetail();
  }, [slug]);

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ my: 3 }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>{" "}
        </Grid>
      </Container>
    );
  }

  if (!license) {
    return (
      <Alert severity="warning" sx={{ my: 3 }}>
        <AlertTitle>Not Found</AlertTitle>
        License not found.
      </Alert>
    );
  }

  return (
    <Container className="link-no-decoration">
      <Box sx={{ maxWidth: "1200px", mx: "auto", my: 4, px: 2 }}>
        {/* Breadcrumbs Navigation */}
        <Breadcrumbs
          separator={<ChevronRightIcon fontSize="small" />}
          sx={{ mb: 3 }}
        >
          <MuiLink
            component={RouterLink}
            to="/ai"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
            AI Services Home
          </MuiLink>
          <MuiLink
            component={RouterLink}
            to="/software-licensing"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <LibraryBooksIcon sx={{ mr: 0.5 }} fontSize="small" />
            Software Licensing
          </MuiLink>
          <Typography
            color="text.primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DescriptionIcon sx={{ mr: 0.5 }} fontSize="small" />
            {license.name}
          </Typography>
        </Breadcrumbs>

        <Card sx={{ mb: 4 }}>
          <CardHeader
            title={
              <Typography variant="h4">
                {license.name}{" "}
                {license.version && (
                  <Typography
                    component="span"
                    variant="h5"
                    color="text.secondary"
                  >
                    v{license.version}
                  </Typography>
                )}
              </Typography>
            }
          />
          <CardContent>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item md={8} xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  {license.summary}
                </Typography>
                {license.official_url && (
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<LaunchIcon />}
                    href={license.official_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      backgroundColor: tertColor,
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: tertHoverColor,
                        color: "#fff",
                      },
                    }}
                  >
                    View Official License
                  </Button>
                )}
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "flex-start", md: "flex-end" },
                }}
              >
                <Chip
                  label={license.license_type}
                  color={
                    license.license_type === "Permissive"
                      ? "success"
                      : "warning"
                  }
                  sx={{ px: 1, py: 0.5, fontSize: "1rem" }}
                />
                {license.osi_approved && (
                  <Chip
                    label="OSI Approved"
                    color="info"
                    sx={{ mt: 1, px: 1, py: 0.5 }}
                  />
                )}
              </Grid>
            </Grid>

            <Typography variant="h6" sx={{ mb: 2 }}>
              License Terms
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 4 }}>
              <Table>
                <TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{ width: "30%", fontWeight: "bold" }}
                      >
                        Permissive Use
                      </TableCell>
                      <TableCell>
                        {license.permissive_use_display || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Attribution Required
                      </TableCell>
                      <TableCell>
                        {license.attribution_display || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Derivative Works
                      </TableCell>
                      <TableCell>
                        {license.derivative_works || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Patent Grant
                      </TableCell>
                      <TableCell>
                        {license.patent_grant_display || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Commercial Use
                      </TableCell>
                      <TableCell>
                        {license.commercial_use_display || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Copyleft
                      </TableCell>
                      <TableCell>
                        {license.copyleft_display || "No Data"}
                      </TableCell>
                    </TableRow>
                    {license.copyleft && (
                      <TableRow>
                        <TableCell component="th" sx={{ fontWeight: "bold" }}>
                          Copyleft Scope
                        </TableCell>
                        <TableCell>
                          {license.copyleft_scope || "No Data"}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Distribution
                      </TableCell>
                      <TableCell>{license.distribution || "No Data"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Sublicensing
                      </TableCell>
                      <TableCell>
                        {license.sublicensing_display || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Private Use
                      </TableCell>
                      <TableCell>
                        {license.private_use_display || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Trademark Use
                      </TableCell>
                      <TableCell>
                        {license.trademark_use || "No Data"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Warranty
                      </TableCell>
                      <TableCell>{license.warranty || "No Data"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Liability
                      </TableCell>
                      <TableCell>{license.liability || "No Data"}</TableCell>
                    </TableRow>
                  </TableBody>
                </TableBody>
              </Table>
            </TableContainer>

            {license.compliance_checklist &&
              license.compliance_checklist.length > 0 && (
                <>
                  <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    Compliance Checklist
                  </Typography>
                  <Paper sx={{ mb: 4 }}>
                    <List>
                      {license.compliance_checklist.map((item, index) => (
                        <ListItem
                          key={index}
                          divider={
                            index < license.compliance_checklist.length - 1
                          }
                        >
                          {item}
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </>
              )}

            {license.full_text && (
              <>
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                  Full License Text
                </Typography>
                <Paper sx={{ mb: 4 }}>
                  <Box sx={{ p: 2 }}>
                    <Box
                      component="pre"
                      sx={{
                        whiteSpace: "pre-wrap",
                        maxHeight: "400px",
                        overflow: "auto",
                        fontFamily: "monospace",
                        fontSize: "0.875rem",
                        m: 0,
                      }}
                    >
                      {license.full_text}
                    </Box>
                  </Box>
                </Paper>
              </>
            )}

            {license.notes && (
              <>
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                  Additional Notes
                </Typography>
                <Paper sx={{ mb: 4, p: 2 }}>
                  <Typography variant="body1">{license.notes}</Typography>
                </Paper>
              </>
            )}
          </CardContent>
        </Card>

        {license.compatible_licenses &&
          license.compatible_licenses.length > 0 && (
            <Card sx={{ mb: 4 }}>
              <CardHeader
                title={
                  <Typography variant="h6">Compatible Licenses</Typography>
                }
              />
              <CardContent>
                <Paper>
                  <List>
                    {license.compatible_licenses.map((compat, index) => (
                      <ListItem
                        key={index}
                        divider={index < license.compatible_licenses.length - 1}
                      >
                        <RouterLink
                          to={`/software-licensing/${compat.slug}`}
                          style={{ textDecoration: "none", color: "#1976d2" }}
                        >
                          {compat.name} {compat.version && `v${compat.version}`}
                        </RouterLink>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </CardContent>
            </Card>
          )}
        <Button
          component={RouterLink}
          to="/software-licensing"
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          sx={{ mt: 3 }}
        >
          Back to License List
        </Button>
      </Box>
    </Container>
  );
};

export default KthSoftLicDetail;
