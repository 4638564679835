import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CircularProgress,
  Alert,
  AlertTitle,
  Typography,
  Chip,
  Box,
  Tooltip,
  IconButton,
  Container,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import BusinessIcon from "@mui/icons-material/Business";
import StarIcon from "@mui/icons-material/Star";
import WarningIcon from "@mui/icons-material/Warning";
import ArchitectureIcon from "@mui/icons-material/Memory";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const KthAiModels = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "AI Models for Patent Law | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { headerTextColor } = baseConfig.siteBranding;
  const [aiModels, setAiModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAiModels = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrlKth}/kth-ai/models`);
        setAiModels(response.data);
      } catch (err) {
        setError("Failed to fetch AI models data.");
        console.error("Error fetching AI models:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAiModels();
  }, []);

  const formatParameterCount = (count) => {
    if (!count && count !== 0) return "N/A";
    if (count >= 1_000_000_000) {
      return `${(count / 1_000_000_000).toFixed(1)}B`;
    } else if (count >= 1_000_000) {
      return `${(count / 1_000_000).toFixed(1)}M`;
    } else if (count >= 1_000) {
      return `${(count / 1_000).toFixed(1)}K`;
    }
    return count.toString();
  };

  const getImportanceColor = (importance) => {
    switch (importance) {
      case "Featured":
        return "primary";
      case "Important":
        return "secondary";
      case "Standard":
        return "default";
      default:
        return "default";
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ my: 3 }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>{" "}
        </Grid>
      </Container>
    );
  }

  return (
    <Box sx={{ maxWidth: "1200px", mx: "auto", px: 2, py: 4 }}>
      <Card sx={{ mb: 4 }}>
        <CardHeader
          title={
            <Typography variant="h4">AI Models for IP Law Analysis</Typography>
          }
        />
        <CardContent>
          <Typography variant="body1" paragraph>
            Our curated list of AI models that can assist with intellectual
            property law analysis, including patent research and drafting,
            patent analysis, and more.
          </Typography>

          <TableContainer component={Paper} sx={{ mb: 2, mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Model</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Architecture
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Developer</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Specialization
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>License</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Technical Specs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aiModels.map((model) => (
                  <TableRow
                    key={model.slug}
                    hover
                    sx={
                      model.deprecated
                        ? { backgroundColor: "rgba(255, 0, 0, 0.05)" }
                        : {}
                    }
                  >
                    <TableCell>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Link
                          to={`/ai-models/${model.slug}`}
                          style={{
                            textDecoration: "none",
                            color: "#1976d2",
                            fontWeight: "bold",
                          }}
                        >
                          {model.title}
                        </Link>
                        {model.version && (
                          <Typography variant="caption" color="text.secondary">
                            v{model.version}
                          </Typography>
                        )}
                        {model.importance === "Featured" && (
                          <Tooltip title="Featured AI Model">
                            <StarIcon fontSize="small" color="primary" />
                          </Tooltip>
                        )}
                        {model.deprecated && (
                          <Tooltip title="Deprecated or Superseded Model">
                            <WarningIcon fontSize="small" color="warning" />
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {model.model_architecture &&
                        model.model_architecture.length > 0
                          ? model.model_architecture.map((arch, idx) => (
                              <Chip
                                key={idx}
                                icon={<ArchitectureIcon fontSize="small" />}
                                label={arch.name}
                                size="small"
                                variant="outlined"
                              />
                            ))
                          : "Not Specified"}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {model.model_owner ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <BusinessIcon fontSize="small" color="action" />
                          <Typography variant="body2">
                            {model.model_owner}
                          </Typography>
                        </Box>
                      ) : (
                        "Not Specified"
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {model.legal_specific && (
                          <Chip
                            label="Legal"
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        )}
                        {model.patent_analysis_capability && (
                          <Chip
                            label="Patent Analysis"
                            size="small"
                            color="secondary"
                            variant="outlined"
                          />
                        )}
                        {!model.legal_specific &&
                          !model.patent_analysis_capability &&
                          "General Purpose"}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {model.license_info ? (
                        <Link
                          to={`/software-licensing/${model.license_info.slug}`}
                          style={{ textDecoration: "none", color: "#1976d2" }}
                        >
                          {model.license_info.name}
                        </Link>
                      ) : (
                        "Not Specified"
                      )}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        {model.parameter_count && (
                          <Typography variant="body2">
                            <strong>Parameters:</strong>{" "}
                            {formatParameterCount(model.parameter_count)}
                          </Typography>
                        )}
                        {model.context_window_size && (
                          <Typography variant="body2">
                            <strong>Context:</strong>{" "}
                            {model.context_window_size.toLocaleString()} tokens
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {aiModels.length === 0 && (
            <Alert severity="info">
              <AlertTitle>No Models Found</AlertTitle>
              There are currently no AI models available for display.
            </Alert>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default KthAiModels;
