import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Container, Button, Divider, Box } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ReusableTitle from "../common/ReusableTitle";
import { faMicrochipAi } from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../common/SetDocumentMeta";
import BodyContentBlock from "../common/BodyContentBlock";
import KthAiLabeling from "./KthAiLabeling";
import KthAiEvaluation from "./KthAiEvaluation";
import KthAiPatents from "./KthAiPatents";
import * as CONFIG from "./utils/kthGlobalLabelsVars";

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const KthServicesAi = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;
  SetDocumentMeta({
    title: "AI Technology Lawyer | Evaluation, Compliance & Data Annotation",
    description:
      "Ensure your AI systems are accurate, compliant, and optimized for legal analysis. Our expert legal team provides AI evaluation, training data labeling, and annotation services tailored to your needs. Specializing in AI patent tools.",
    keywords:
      "AI legal evaluation, AI training data labeling, AI annotation, legal AI services, AI compliance, AI in intellectual property law, AI for patent analysis",
  });

  // Check if there is a # in URL and if so navigate to that anchor
  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the '#' character
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  // Define the content for the body
  const bodyContent = [
    {
      contentId: "ai_patents",
      image: "kth-ai-in-patent-analysis.png",
      title: "AI in Patent Analysis",
      text: "Kama Thuo, PLLC counsels clients on the effective use of AI in patent analysis, including prior art searching, identifying patent licensing targets, and \
      understanding portfolio content and gaps. In addition to analyzing patents, we guide clients on selecting or using AI patent drafting or analysis tools, \
      training, fine-tuning, and integrating patent-specific AI models designed to enhance accuracy, efficiency, and strategic insight in patent law.",
      imagePosition: "right",
      link: "#ai-patent-analysis",
      linkExternal: false,
    },
    {
      contentId: "ai_evaluation",
      image: "kth-ai-guidance.png",
      title: "AI Application Evaluation",
      text: "Kama Thuo, PLLC provides specialized legal evaluation services for AI applications, emphasizing the intricate technical and intellectual property \
      considerations inherent in AI systems. We can guide you through the complexities of multi-component AI workflows—both agentic and non-agentic—by meticulously \
      reviewing layered licensing and contractual agreements governing each AI component to identify critical provisions impacting overall system compliance, IP rights, \
      and contractual obligations. Additionally, we rigorously evaluate AI-generated outputs to determine potential IP infringement, \
      contractual violations, and accuracy of AI-driven legal analyses. Our deep technical and legal expertise ensures that clients confidently integrate AI technologies \
      while minimizing legal and operational risks.",
      imagePosition: "left",
      link: "#ai-legal-evaluation",
      linkExternal: false,
      newsLink: "/news-insights?category=ai-evaluation",
      newsLinkExternal: false,
    },
    {
      contentId: "ai_labeling_services",
      image: "kth-head-2-ai.png",
      title:
        "Legal Data Annotation and Prompt Engineering for Complex Legal Reasoning",
      text: "Kama Thuo, PLLC supports clients developing sophisticated AI-powered legal analysis tools, with a particular focus on patent law and complex technology \
      transactions. Our services include designing advanced legal reasoning prompts for AI model training and fine-tuning, engineering accurate and contextually relevant \
      training datasets, and developing robust scoring methodologies to measure and refine AI model performance. As a law firm, we uniquely provide attorney-client privilege, \
      attorney work-product protections, and strict confidentiality standards, particularly valuable when AI data annotation tasks relate to litigation preparation or legal opinion formulation. \
      Our combination of deep technical understanding and legal expertise allows us to deliver nuanced, secure, and precise AI data solutions that enhance your legal \
      AI tools’ accuracy and effectiveness",
      imagePosition: "right",
      link: "#ai-legal-annotation",
      linkExternal: false,
      newsLink: "/news-insights?category=ai-training-fine-tuning",
      newsLinkExternal: false,
    },
    {
      contentId: "ai_transactions",
      image: "kth-ai-transactions.png",
      title: "AI Technology Transactions",
      text: "At Kama Thuo, PLLC, we specialize in negotiating, drafting, and reviewing complex technology agreements involving AI solutions. Our services cover AI software licensing \
      agreements, SaaS contracts, model and data licensing agreements, technology transfer contracts, development and co-development agreements, and strategic partnership agreements. \
      We provide counsel to clients on intricate issues involving intellectual property rights allocation, liability, data ownership, security compliance, indemnification clauses, and \
      exit strategies for AI-related technology collaborations and transactions.",
      imagePosition: "left",
      newsLink: "/news-insights?category=tech-transactions",
      newsLinkExternal: false,
    },
    {
      contentId: "ai_litigation",
      image: "kth-ai-litigation.png",
      title: "AI in Litigation",
      text: "Kama Thuo, PLLC supports law firms and corporate clients with comprehensive litigation-related AI services. We conduct meticulous pre-litigation Rule 11 investigations \
      of AI applications and systems, ensuring thorough analysis while safeguarding attorney work product from unintended discovery. We also evaluate AI-generated legal outputs for \
      accuracy, consistency, and evidentiary strength, providing critical support in technologically complex litigation. Our AI expertise enables us to advise on evidentiary \
      admissibility, AI model defensibility, and strategy in high-stakes technology disputes.",
      imagePosition: "right",
      newsLink: "/news-insights?practice=ai",
      newsLinkExternal: false,
    },
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>
          <ReusableTitle
            title="Legal Counseling for AI & ML"
            isMainHeading={true}
            siteBranding={baseConfig.siteBranding}
            icon={{ faIcon: faMicrochipAi }}
          />
        </Container>
        <Container>
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/news-insights?practice=ai"
          >
            News & Insights: AI
          </Button>
          <Button
            startIcon={<HomeRepairServiceIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/patent-ai-resources"
          >
            Patent AI Resources
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12}>
        {bodyContent.map((content, index) => (
          <BodyContentBlock
            key={index}
            content={content}
            baseConfig={baseConfig}
          />
        ))}
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Container>{CONFIG.RFW_HORIZDOT}</Container>
      </Grid>

      <Grid item xs={12} id="ai-patent-analysis">
        <KthAiPatents baseConfig={baseConfig} />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>{CONFIG.RFW_HORIZDOT}</Container>
      </Grid>

      <Grid item xs={12} id="ai-legal-evaluation">
        <KthAiEvaluation baseConfig={baseConfig} />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>{CONFIG.RFW_HORIZDOT}</Container>
      </Grid>

      <Grid item xs={12} id="ai-legal-annotation">
        <KthAiLabeling baseConfig={baseConfig} />
      </Grid>
    </Grid>
  );
};

export default KthServicesAi;
