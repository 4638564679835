import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
  Link as MuiLink,
  Chip,
  Card,
  CardContent,
  Divider,
  useTheme,
  useMediaQuery,
  Breadcrumbs,
  Collapse,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFilePdf,
  faExternalLinkAlt,
  faLink,
  faGavel,
  faToggleOn,
  faBracketsCurly,
  faCompassDrafting,
  faArrowLeft,
  faBook,
  faGlobe,
  faScaleBalanced,
} from "@fortawesome/pro-solid-svg-icons";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DescriptionIcon from "@mui/icons-material/Description";
import LaunchIcon from "@mui/icons-material/Launch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

// Styled components
const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
}));

const DefinitionSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));

const SourceCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const ImportanceChip = styled(Chip)(({ theme, importance }) => {
  const colors = {
    Featured: theme.palette.error.main,
    Important: theme.palette.warning.main,
    Standard: theme.palette.success.main,
    Low: theme.palette.grey[500],
  };

  return {
    backgroundColor: colors[importance] || theme.palette.grey[500],
    color: "#fff",
    fontWeight: "bold",
  };
});

const AiDefinitionDetail = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "AI Legal Definition Detail | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { tertHoverColor, tertColor } = baseConfig.siteBranding;
  const { slug } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [definition, setDefinition] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedReferences, setExpandedReferences] = useState({});

  useEffect(() => {
    const fetchDefinitionDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-ai/${slug}/definition-detail`
        );
        setDefinition(response.data);

        // Initialize expanded state for references
        if (response.data.reflinks_for_legal_defn?.length > 0) {
          const expandedState = {};
          response.data.reflinks_for_legal_defn.forEach((ref, index) => {
            expandedState[index] = true; // Default to accordions open - make false to default to closed.
          });
          setExpandedReferences(expandedState);
        }
      } catch (err) {
        setError("Failed to fetch AI definition detail.");
        console.error("Error fetching AI definition detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDefinitionDetail();
    // Scroll to top when loading a new definition
    window.scrollTo(0, 0);
  }, [slug]);

  const toggleReferenceExpansion = (index) => {
    setExpandedReferences((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (isLoading) {
    return (
      <Container
        maxWidth="lg"
        sx={{ py: 6, display: "flex", justifyContent: "center" }}
      >
        <CircularProgress size={60} thickness={4} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <StyledAlert severity="error" variant="filled">
          {error}
        </StyledAlert>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="primary"
          variant="contained"
          component={RouterLink}
          to="/legal-definitions-for-ai"
          sx={{ mt: 2, mb: 2 }}
        >
          Return to AI Definitions Search
        </Button>
      </Container>
    );
  }

  if (!definition) {
    return (
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <StyledAlert severity="warning" variant="filled">
          No AI definition data found.
        </StyledAlert>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="primary"
          variant="contained"
          component={RouterLink}
          to="/legal-definitions-for-ai"
          sx={{ mt: 2, mb: 2 }}
        >
          Return to AI Definitions Search
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className="link-no-decoration" sx={{ py: 4 }}>
      {/* Breadcrumbs Navigation */}
      <Breadcrumbs
        separator={<ChevronRightIcon fontSize="small" />}
        sx={{ mb: 3 }}
      >
        <MuiLink
          component={RouterLink}
          to="/ai"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
          AI Services Home
        </MuiLink>
        <MuiLink
          component={RouterLink}
          to="/legal-definitions-for-ai"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LibraryBooksIcon sx={{ mr: 0.5 }} fontSize="small" />
          AI Legal Definitions
        </MuiLink>
        <Typography
          color="text.primary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <DescriptionIcon sx={{ mr: 0.5 }} fontSize="small" />
          {definition.term}
        </Typography>
      </Breadcrumbs>

      {/* Header Section */}
      <DefinitionSection elevation={3}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4" component="h1">
            {definition.term}
          </Typography>
        </Box>

        <Divider sx={{ mb: 3, bgcolor: "rgba(255,255,255,0.2)" }} />

        <Typography variant="h6" gutterBottom>
          Common Understanding/ Plain and Ordinary Meaning
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: definition.common_meaning }}
        />
      </DefinitionSection>

      {/* Legal Definitions Section */}
      {definition.construed_definitions?.length > 0 ? (
        <DefinitionSection elevation={2}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <FontAwesomeIcon
              icon={faGavel}
              style={{ marginRight: 12, fontSize: 24 }}
            />
            <Typography variant="h4" component="h2">
              Legal Definitions
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={3}>
            {definition.construed_definitions.map((legalDef, index) => (
              <Grid item xs={12} key={index}>
                <SourceCard elevation={1}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      sx={{ color: tertColor }}
                    >
                      {legalDef.source}
                    </Typography>

                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}
                    >
                      {legalDef.source_type?.name && (
                        <Chip
                          icon={<FontAwesomeIcon icon={faBook} />}
                          label={legalDef.source_type.name}
                          variant="outlined"
                          color="primary"
                          component={RouterLink}
                          to={`/legal-definitions-for-ai?source_type=${legalDef.source_type.name}`}
                          clickable
                        />
                      )}

                      {legalDef.source_jurisdiction?.name && (
                        <Chip
                          icon={<FontAwesomeIcon icon={faGlobe} />}
                          label={legalDef.source_jurisdiction.name}
                          variant="outlined"
                          color="secondary"
                          component={RouterLink}
                          to={`/legal-definitions-for-ai?source_jurisdiction=${legalDef.source_jurisdiction.name}`}
                          clickable
                        />
                      )}

                      {legalDef.first_published && (
                        <Chip
                          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                          label={legalDef.first_published}
                          variant="outlined"
                        />
                      )}
                    </Box>

                    {legalDef.source_details && (
                      <Typography
                        variant="body2"
                        sx={{ mb: 2, fontStyle: "italic" }}
                      >
                        {legalDef.source_details}
                      </Typography>
                    )}

                    <Box
                      sx={{
                        p: 2,
                        bgcolor: theme.palette.background.default,
                        borderRadius: 1,
                        // borderLeft: `4px solid ${theme.palette.primary.main}`,
                        borderLeft: `4px solid ${tertColor}`,
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: legalDef.legal_meaning,
                        }}
                        sx={{ "& p": { mt: 1, mb: 1 } }}
                      />
                    </Box>

                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      {legalDef.source_link && (
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<LaunchIcon />}
                          href={legalDef.source_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {legalDef.source_link_label || "View Source"}
                        </Button>
                      )}

                      {legalDef.source_doc && (
                        <Button
                          variant="text"
                          color="secondary"
                          startIcon={<FontAwesomeIcon icon={faFilePdf} />}
                          href={legalDef.source_doc}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {legalDef.source_doc_label || "View Document"}
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </SourceCard>
              </Grid>
            ))}
          </Grid>
        </DefinitionSection>
      ) : (
        // <StyledAlert severity="info">
        //   No official legal definitions found for this term.
        // </StyledAlert>
        <Alert severity="info">
          <AlertTitle>No Legal Definitions Found</AlertTitle>
          No official legal definitions found for this term.
        </Alert>
      )}

      {/* References Section */}
      {definition.reflinks_for_legal_defn?.length > 0 && (
        <DefinitionSection elevation={2}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <FontAwesomeIcon
              icon={faLink}
              style={{ marginRight: 12, fontSize: 24 }}
            />
            <Typography variant="h4" component="h2">
              References & Resources
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          {definition.reflinks_for_legal_defn.map((ref, index) => (
            <Card key={index} sx={{ mb: 3, borderRadius: 2 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" component="h3" color={tertColor}>
                    {ref.reflink_description_title}
                  </Typography>
                  <IconButton
                    onClick={() => toggleReferenceExpansion(index)}
                    size="small"
                  >
                    {expandedReferences[index] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>

                <Collapse
                  in={expandedReferences[index] || false}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body1"
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: ref.reflink_description,
                      }}
                      sx={{ mb: 2 }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      endIcon={<LaunchIcon />}
                      href={ref.reflink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        mt: 1,
                        backgroundColor: tertColor,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: tertHoverColor,
                          color: "#fff",
                        },
                      }}
                    >
                      {ref.reflink_label || "View Resource"}
                    </Button>
                  </Box>
                </Collapse>
              </CardContent>
            </Card>
          ))}
        </DefinitionSection>
      )}

      {/* Related Terms Section */}
      {definition.related_terms?.length > 0 && (
        <DefinitionSection elevation={2}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <FontAwesomeIcon
              icon={faBracketsCurly}
              style={{ marginRight: 12, fontSize: 24 }}
            />
            <Typography variant="h5" component="h2">
              Related Terms
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            {definition.related_terms.map((related, index) => (
              <Chip
                key={index}
                label={related.related_term_name}
                component={RouterLink}
                to={`/legal-definitions-for-ai/${related.related_term_slug}`}
                clickable
                color="primary"
                variant="outlined"
                sx={{
                  pl: 1,
                  pr: 1,
                  fontSize: "0.9rem",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                    transform: "translateY(-2px)",
                  },
                }}
              />
            ))}
          </Box>
        </DefinitionSection>
      )}

      {/* Back button at the bottom */}
      <Box sx={{ mt: 4, mb: 4, display: "flex" }}>
        <Button
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to="/legal-definitions-for-ai"
        >
          Back to All Definitions
        </Button>
      </Box>
    </Container>
  );
};

export default AiDefinitionDetail;
