import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmbededContactForm from "./EmbededContactForm";
import { getImageUrl } from "../../utils/helpers";
import NumberedList from "../common/NumberedList";
import SetDocumentMeta from "../common/SetDocumentMeta";
import CustomAccordion from "../common/CustomAccordion";

const AMZPatentEnforcement = ({ baseConfig }) => {
  const { tertColor } = baseConfig.siteBranding;
  SetDocumentMeta({
    title: "Amazon Patent Enforcement & Defense Law Firm",
    description:
      "We help patent owners enforce their patent rights on Amazon sellers, and defend Amazon sellers against infringement allegations. Our engineering & technology law firm excels at high-tech patent enforcement, Amazon patent disputes, and IP protection.",
    keywords:
      "Amazon patent enforcement, cease & desist letter, patent compliance, Amazon sellers, patent infringement, engineering law firm, technology patent disputes, IP protection, APEX program, brand registry",
  });
  return (
    <Container sx={{ py: 4 }}>
      {/* ======== HEADER / BANNERS ======== */}
      <Box component="header" textAlign="center" mb={4}>
        {/* Amazon Logo + Headline (Same Row) */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "#fff", p: 1 }}
        >
          <img
            src={getImageUrl("amz-pat-disputes.png")}
            alt="Amazon Patent Disputes"
            style={{
              width: "180px",
              height: "auto",
              marginRight: 10,
            }}
          />
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Patent Disputes
          </Typography>
        </Box>

        <Typography variant="body1" textAlign="left">
          We help patent owners enforce their patent rights on Amazon sellers,
          and we defend Amazon sellers against patent infringement allegations.
          This includes drafting and responding to Cease and Desist letters,
          investigating patent infringement, analyzing patents against accused
          ASINs, negotiating licensing or settlement, and more.
        </Typography>

        {/* Banners */}
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          gap={2} // SPACE BETWEEN BANNERS
          mt={3}
        >
          {/* Patent Owner Banner */}
          <Box
            sx={{
              backgroundColor: "#007BFF",
              color: "#fff",
              borderRadius: 2,
              p: 2,
              textAlign: "center",
              cursor: "pointer",
              flex: "1 1 300px",
              maxWidth: "350px",
            }}
            onClick={() =>
              document
                .getElementById("for-patent-owners")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <Typography variant="h6" fontWeight="bold" m={0}>
              I’m a Patent Owner
            </Typography>
            <Typography variant="body2" m={0}>
              Enforce my patent rights on Amazon sellers
            </Typography>
          </Box>

          {/* Amazon Seller Banner */}
          <Box
            sx={{
              backgroundColor: "#28a745",
              color: "#fff",
              borderRadius: 2,
              p: 2,
              textAlign: "center",
              cursor: "pointer",
              flex: "1 1 300px",
              maxWidth: "350px",
            }}
            onClick={() =>
              document
                .getElementById("for-amazon-sellers")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <Typography variant="h6" fontWeight="bold" m={0}>
              I’m an Amazon Seller
            </Typography>
            <Typography variant="body2" m={0}>
              Defend my Amazon listing against patent claims
            </Typography>
          </Box>

          {/* Free Strategy Call Banner (Compact) */}
          <Box
            sx={{
              backgroundColor: "#ff9800", // Orange to signify action
              color: "#fff",
              borderRadius: 2,
              p: 2,
              textAlign: "left",
              cursor: "pointer",
              flex: "1 1 300px",
              maxWidth: "350px",
              display: "flex",
              alignItems: "center", // Center icon and text vertically
            }}
            onClick={() =>
              document
                .getElementById("strategy-call")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <PhoneInTalkIcon sx={{ fontSize: 28, mr: 2 }} />
            <Box>
              <Typography variant="h6" fontWeight="bold" m={0}>
                Schedule Free Strategy Call
              </Typography>
              <Typography variant="body2" m={0}>
                Get expert legal guidance on patent assertion & defense
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* ======== TABLE OF CONTENTS ======== */}
      <Card variant="outlined" sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Jump to Section
          </Typography>
          <List dense disablePadding>
            {[
              { id: "for-patent-owners", label: "For Patent Owners" },
              { id: "for-amazon-sellers", label: "For Amazon Sellers" },
              { id: "why-work-with-us", label: "Why Work With Us" },
              { id: "strategy-call", label: "Schedule Free Strategy Call" },
            ].map((section) => (
              <ListItem key={section.id} disablePadding>
                <ListItemButton
                  onClick={() =>
                    document
                      .getElementById(section.id)
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowCircleRight}
                    style={{ marginRight: 8 }}
                  />
                  <ListItemText primary={section.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      {/* ======== FOR PATENT OWNERS SECTION ======== */}
      <Box id="for-patent-owners" mb={4}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            backgroundColor: "#007BFF",
            color: "#fff",
            p: 2,
            borderRadius: 1,
          }}
        >
          For Patent Owners
        </Typography>

        <CustomAccordion title="Enforcing Your Patent Rights on Amazon">
          <Typography variant="body1" paragraph>
            If you own a patent and have discovered infringing products on
            Amazon, you have multiple enforcement pathways. One option is to
            submit a Notice of Patent Infringement through Amazon’s internal
            processes, which can lead to rapid removal of the offending ASIN. In
            complex cases, Amazon may direct you to its Patent Evaluation
            Express (APEX) program, where a neutral evaluator—a patent
            attorney—reviews the dispute.
          </Typography>
          <Typography variant="body1" paragraph>
            Our firm focuses on high-tech electrical and mechanical patents,
            especially in the realms of computer hardware, electronics, and
            various mechanical products sold online. Led by an experienced
            patent attorney and licensed professional engineer (Electrical), we
            have extensive experience drafting, prosecuting, analyzing,
            enforcing, and defending patent claims in these fields. This
            technical background helps us offer targeted guidance and robust
            enforcement strategies.
          </Typography>
        </CustomAccordion>

        <CustomAccordion
          title="Action Steps Overview (Patent Owners)"
          defaultExpanded
        >
          <Typography variant="body1" paragraph>
            Below is a brief breakdown of recommended steps for patent owners,
            followed by what our law firm can do to assist. While this overview
            provides general guidance, every situation is unique and may require
            a customized strategy.
          </Typography>

          <NumberedList
            numColor={tertColor}
            title="What Patent Owners Can Do"
            items={[
              "<strong>Enroll in Amazon Brand Registry (If Possible):</strong> This streamlines the takedown process by providing faster tools for reporting infringement. Brand Registry usually requires a registered or pending trademark.",
              "<strong>Gather and Preserve Evidence:</strong> Conduct a 'test buy' of the allegedly infringing product. Save screenshots, photos, invoices—anything that proves the product’s features match your patent claims.",
              "<strong>Prepare a Focused Complaint:</strong> Clearly map your patent claims to the infringing product. Amazon isn’t staffed by patent attorneys, so concise, visual explanations help.",
              "<strong>Evaluate Risk of Retaliation:</strong> If the accused seller is large or litigious, be aware of potential declaratory judgment actions in federal court.",
              "<strong>File the Complaint or Escalate to APEX:</strong> Submit your evidence via Brand Registry or the Report a Violation tool. If the matter is complex, prepare to escalate it to APEX for a binding decision.",
            ]}
          />

          <NumberedList
            numColor={tertColor}
            title="What Our Law Firm Will Do"
            items={[
              "<strong>Conduct Preliminary Analysis:</strong> We review patent claims and compare them to the target product for potential infringement or noninfringement.",
              "<strong>Draft & Strategize:</strong> We prepare or refine your complaint, ensuring it highlights the correct claims and supporting evidence.",
              "<strong>Navigate Amazon Procedures:</strong> We handle Brand Registry or APEX submissions to ensure your arguments are effectively presented.",
              "<strong>Mitigate Litigation Risks:</strong> We advise on ways to minimize exposure or respond effectively if the accused seller pursues litigation.",
              "<strong>Offer Comprehensive IP Support:</strong> Beyond Amazon, we provide patent filing, prosecution, and broader IP services to safeguard or expand your portfolio.",
            ]}
          />
        </CustomAccordion>

        <CustomAccordion title="Patent Owners FAQ" defaultExpanded>
          <List>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="Do I need a utility patent to enforce on Amazon?"
                secondary="Amazon generally requires a granted utility or design patent for takedown requests. Provisional applications aren’t enough."
              />
            </ListItem>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="How quickly are takedown requests resolved?"
                secondary="Simple cases can be resolved within a few weeks; more complex disputes, especially those escalated to APEX, may take several months."
              />
            </ListItem>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="Can Amazon sellers challenge my patent’s validity?"
                secondary="Within Amazon’s internal process (APEX), sellers focus on noninfringement. Outside Amazon, they could pursue invalidity in court or at the USPTO."
              />
            </ListItem>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="What if the infringer changes their product?"
                secondary="Regularly monitor the ASIN for changes. If the seller modifies the product to remove infringing features, you may need a new strategy."
              />
            </ListItem>
          </List>
        </CustomAccordion>
      </Box>

      {/* ======== FOR AMAZON SELLERS SECTION ======== */}
      <Box id="for-amazon-sellers" mb={4}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            backgroundColor: "#28a745",
            color: "#fff",
            p: 2,
            borderRadius: 1,
          }}
        >
          For Amazon Sellers
        </Typography>

        <CustomAccordion
          title="Defending Against Patent Claims"
          defaultExpanded
        >
          <Typography variant="body1" paragraph>
            If you’re an Amazon seller accused of patent infringement or facing
            an ASIN suspension, you have options. Whether you’re invited to APEX
            (Amazon’s neutral evaluation) or have received a takedown notice, we
            can help assess noninfringement arguments or negotiate a resolution.
          </Typography>
          <Typography variant="body1" paragraph>
            Our services include preliminary patent analyses to evaluate your
            product’s design against the asserted patent claims. If a strong
            defense is viable, we can help you argue noninfringement or find a
            workable agreement with the patent owner, allowing you to continue
            selling without interruption.
          </Typography>
        </CustomAccordion>

        <CustomAccordion title="Amazon Sellers FAQ" defaultExpanded>
          <List>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="What if my listing is wrongfully taken down?"
                secondary="You can respond with a noninfringement defense. If needed, escalate through Amazon’s processes or consider outside legal action."
              />
            </ListItem>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="Do I have to participate in the APEX process?"
                secondary="Sellers can decline APEX, but that often means the listing will remain down unless you pursue other means (e.g., direct negotiation or litigation)."
              />
            </ListItem>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="How can I prepare a strong defense?"
                secondary="Work with a patent attorney to compare your product’s features against the patent claims. Gather documentation and any evidence showing noninfringement."
              />
            </ListItem>
            <ListItem>
              <ChevronRightIcon
                sx={{ fontSize: 20, color: tertColor, mr: 1 }}
              />
              <ListItemText
                primary="What are my options if APEX rules against me?"
                secondary="You may still litigate in court or redesign your product to avoid infringing features. Other defenses such as invalidity might be available to you in other forums."
              />
            </ListItem>
          </List>
        </CustomAccordion>
      </Box>

      {/* ======== WHY WORK WITH US SECTION ======== */}
      <Box id="why-work-with-us" mb={4}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            backgroundColor: "#6c757d",
            color: "#fff",
            p: 2,
            borderRadius: 1,
          }}
        >
          Why Work With Us
        </Typography>
        <CustomAccordion title="Our Approach & Capabilities" defaultExpanded>
          <Typography variant="body1" paragraph>
            <strong>Technical Expertise:</strong> Our lead attorney is not only
            a registered patent attorney but also a licensed professional
            engineer (Electrical). We excel at understanding the complexities
            behind electrical and electronic innovations.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Strategic Guidance:</strong> We tailor enforcement and
            defense strategies to each client’s priorities—whether it’s swiftly
            removing infringing listings or protecting legitimate sellers from
            wrongful accusations.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Comprehensive Services:</strong> From preliminary
            noninfringement analyses to licensing negotiations, patent filings,
            and more, we offer a full spectrum of IP support for online
            businesses.
          </Typography>
          <Typography variant="body1">
            <strong>Responsive &amp; Efficient:</strong> E-commerce moves fast,
            so we do too! We’re committed to quick turnarounds, helping you
            safeguard or assert your patent rights without delay.
          </Typography>
        </CustomAccordion>
      </Box>

      {/* ======== STRATEGY CALL BANNER / CTA ======== */}
      <Typography
        id="strategy-call"
        variant="h5"
        fontWeight="bold"
        sx={{
          backgroundColor: "#ff9800",
          color: "#fff",
          p: 2,
          borderRadius: 1,
        }}
      >
        Free Strategy Call
      </Typography>

      <Box
        component="section"
        sx={{ mt: 0, p: 3, borderRadius: 2, backgroundColor: "#f3f6f9" }}
      >
        {/* Title spanning both columns */}
        <Box textAlign="center" mb={3}>
          <Typography variant="h5" fontWeight="bold">
            Ready to Protect or Defend Your Amazon Listings?
          </Typography>
          <Typography variant="body1" fontWeight="bold" sx={{ mt: 1 }}>
            Need Immediate Assistance? Call:{" "}
            <strong style={{ color: tertColor }}>+1-602-837-0631</strong>
          </Typography>
        </Box>

        <Grid container spacing={4} alignItems="center">
          {/* LEFT SIDE: Image + Overview */}
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center" mb={1}>
              <img
                src={getImageUrl("pat-strat-amz-banner-600x400v2.png")}
                alt="Patent Consultation"
                style={{ width: "100%", maxWidth: "600px", height: "auto" }}
              />
            </Box>

            <Typography variant="h6" fontWeight="bold" sx={{ mt: 6 }}>
              What to Expect in Your Free Strategy Call:
            </Typography>

            <List dense sx={{ mb: 1 }}>
              {[
                "We will assess your case and collect info for conflicts check, and",
                "provide a no-obligation flat-fee quote.",
              ].map((text, index) => (
                <ListItem key={index} sx={{ py: 0 }}>
                  <ChevronRightIcon
                    sx={{ fontSize: 16, color: tertColor, mr: 1 }}
                  />
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>

            {[
              {
                title: "For Patent Owners",
                items: [
                  "We will outline Amazon patent enforcement procedures and risks, and",
                  "identify immediate actions and long-term strategies.",
                ],
              },
              {
                title: "For Amazon Sellers",
                items: [
                  "We will review potential defenses and legal strategies, and",
                  "explain tradeoffs between defense options, including APEX.",
                ],
              },
            ].map((section, index) => (
              <Box key={index} sx={{ ml: 2, mt: 1 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {section.title}
                </Typography>
                <List dense>
                  {section.items.map((text, idx) => (
                    <ListItem key={idx} sx={{ py: 0 }}>
                      <ChevronRightIcon
                        sx={{ fontSize: 16, color: tertColor, mr: 1 }}
                      />
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Grid>

          {/* RIGHT SIDE: Reusable Contact Form */}
          <Grid item xs={12} md={6}>
            <EmbededContactForm
              title="Book Your Free Patent Strategy Call"
              subTitle="After completing the form below, we will contact you with available times for a free phone consultation with a patent attorney."
              dropDownOptions={[
                "Enforcing a Patent on Amazon",
                "Defending an Amazon Seller Listing",
                "Other IP Question",
              ]}
              submitButtonLabel="Book My Free Phone Consultation"
              disclaimerHTML="add disclaimer"
            />
          </Grid>
        </Grid>
      </Box>

      {/* ======== FINAL CTA (OPTIONAL) ======== */}
      <Box textAlign="center" mt={6} pt={2} borderTop="1px solid #ccc">
        <Typography variant="body2" fontStyle="italic" mt={2}>
          Disclaimer: We may already represent an adverse party. Please do not
          share confidential details until we confirm no conflicts of interest.
        </Typography>
      </Box>
    </Container>
  );
};

export default AMZPatentEnforcement;
