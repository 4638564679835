import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CircularProgress,
  Alert,
  AlertTitle,
  Typography,
  Chip,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  Container,
  Link as MuiLink,
  Breadcrumbs,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LaunchIcon from "@mui/icons-material/Launch";
import WarningIcon from "@mui/icons-material/Warning";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BusinessIcon from "@mui/icons-material/Business";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import GavelIcon from "@mui/icons-material/Gavel";
import DescriptionIcon from "@mui/icons-material/Description";
import TuneIcon from "@mui/icons-material/Tune";
import CategoryIcon from "@mui/icons-material/Category";
import MemoryIcon from "@mui/icons-material/Memory";
import ScheduleIcon from "@mui/icons-material/Schedule";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArchitectureIcon from "@mui/icons-material/Memory";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const KthAiModelDetail = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Detail of Use of AI Model for Patent Law | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { tertHoverColor, tertColor } = baseConfig.siteBranding;
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchModelDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-ai/${slug}/model-detail`
        );
        setModel(response.data);
      } catch (err) {
        setError("Failed to fetch AI model detail.");
        console.error("Error fetching AI model detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchModelDetail();
  }, [slug]);

  const formatParameterCount = (count) => {
    if (!count && count !== 0) return "N/A";
    if (count >= 1_000_000_000) {
      return `${(count / 1_000_000_000).toFixed(2)} billion`;
    } else if (count >= 1_000_000) {
      return `${(count / 1_000_000).toFixed(2)} million`;
    } else if (count >= 1_000) {
      return `${(count / 1_000).toFixed(2)} thousand`;
    }
    return count.toString();
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ my: 3 }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>{" "}
        </Grid>
      </Container>
    );
  }

  if (!model) {
    return (
      <Alert severity="warning" sx={{ my: 3 }}>
        <AlertTitle>Not Found</AlertTitle>
        AI model not found.
      </Alert>
    );
  }

  return (
    <Container className="link-no-decoration">
      <Box sx={{ maxWidth: "1200px", mx: "auto", px: 2, py: 4 }}>
        {/* Breadcrumbs Navigation */}
        <Breadcrumbs
          separator={<ChevronRightIcon fontSize="small" />}
          sx={{ mb: 3 }}
        >
          <MuiLink
            component={RouterLink}
            to="/ai"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
            AI Services Home
          </MuiLink>
          <MuiLink
            component={RouterLink}
            to="/ai-models"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <LibraryBooksIcon sx={{ mr: 0.5 }} fontSize="small" />
            AI Models
          </MuiLink>
          <Typography
            color="text.primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DescriptionIcon sx={{ mr: 0.5 }} fontSize="small" />
            {model.title}
          </Typography>
        </Breadcrumbs>
        <Card sx={{ mb: 4 }}>
          <CardHeader
            title={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="h4">
                  {model.title}
                  {model.version && (
                    <Typography
                      component="span"
                      variant="h5"
                      color="text.secondary"
                      sx={{ ml: 1 }}
                    >
                      v{model.version}
                    </Typography>
                  )}
                </Typography>
                {model.deprecated && (
                  <Tooltip title="This model has been deprecated or superseded">
                    <Chip
                      icon={<WarningIcon />}
                      label="Deprecated"
                      color="warning"
                    />
                  </Tooltip>
                )}
              </Box>
            }
            subheader={
              <Box sx={{ mt: 1 }}>
                {model.model_architecture &&
                  model.model_architecture.length > 0 &&
                  model.model_architecture.map((arch, idx) => (
                    <Chip
                      key={idx}
                      icon={<ArchitectureIcon />}
                      label={arch.name}
                      variant="outlined"
                      sx={{ mr: 1, mt: 1 }}
                    />
                  ))}
                {model.model_owner && (
                  <Chip
                    icon={<BusinessIcon />}
                    label={model.model_owner}
                    variant="outlined"
                    sx={{ mr: 1, mt: 1 }}
                  />
                )}
                {model.release_date && (
                  <Chip
                    icon={<CalendarTodayIcon />}
                    label={formatDate(model.release_date)}
                    variant="outlined"
                    sx={{ mr: 1, mt: 1 }}
                  />
                )}
                {model.legal_specific && (
                  <Chip
                    icon={<GavelIcon />}
                    label="Legal-Specific"
                    color="primary"
                    sx={{ mr: 1, mt: 1 }}
                  />
                )}
                {model.patent_analysis_capability && (
                  <Chip
                    icon={<DescriptionIcon />}
                    label="Patent Analysis"
                    color="secondary"
                    sx={{ mr: 1, mt: 1 }}
                  />
                )}
              </Box>
            }
          />
          <CardContent>
            {/* Description Section */}
            {model.description && (
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
                >
                  <FormatQuoteIcon /> Description
                </Typography>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: model.description }}
                />
              </Box>
            )}

            <Grid container spacing={4}>
              {/* Technical Specifications */}
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <MemoryIcon /> Technical Specifications
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" sx={{ fontWeight: "bold" }}>
                          Architecture
                        </TableCell>
                        <TableCell>
                          {model.model_architecture &&
                          model.model_architecture.length > 0
                            ? model.model_architecture
                                .map((arch) => arch.name)
                                .join(", ")
                            : "Not specified"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" sx={{ fontWeight: "bold" }}>
                          Parameter Count
                        </TableCell>
                        <TableCell>
                          {model.parameter_count
                            ? formatParameterCount(model.parameter_count)
                            : "Not specified"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" sx={{ fontWeight: "bold" }}>
                          Context Window
                        </TableCell>
                        <TableCell>
                          {model.context_window_size
                            ? `${model.context_window_size.toLocaleString()} tokens`
                            : "Not specified"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" sx={{ fontWeight: "bold" }}>
                          Max Output
                        </TableCell>
                        <TableCell>
                          {model.max_output_tokens
                            ? `${model.max_output_tokens.toLocaleString()} tokens`
                            : "Not specified"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" sx={{ fontWeight: "bold" }}>
                          Training Data Cutoff
                        </TableCell>
                        <TableCell>
                          {model.training_data_cutoff
                            ? formatDate(model.training_data_cutoff)
                            : "Not specified"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" sx={{ fontWeight: "bold" }}>
                          Fine-tunable
                        </TableCell>
                        <TableCell>
                          {model.fine_tunable === true
                            ? "Yes"
                            : model.fine_tunable === false
                            ? "No"
                            : "Not specified"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>

              {/* Legal Capabilities & License */}
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <GavelIcon /> Legal Capabilities
                  </Typography>

                  {model.legal_capabilities ? (
                    <Typography variant="body2" paragraph>
                      {model.legal_capabilities}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      No specific legal capabilities documented.
                    </Typography>
                  )}

                  <Divider sx={{ my: 2 }} />

                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <VerifiedUserIcon /> License Information
                  </Typography>

                  {model.license_info ? (
                    <Box>
                      <Typography variant="body2" gutterBottom>
                        <strong>License:</strong>{" "}
                        <RouterLink
                          to={`/software-licensing/${model.license_info.slug}`}
                          style={{ textDecoration: "none", color: "#1976d2" }}
                        >
                          {model.license_info.name}{" "}
                          {model.license_info.version &&
                            `v${model.license_info.version}`}
                        </RouterLink>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Type:</strong> {model.license_info.type}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      License information not available.
                    </Typography>
                  )}
                </Paper>
              </Grid>

              {/* Access & Applications */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 2,
                        }}
                      >
                        <TuneIcon /> Access Methods
                      </Typography>

                      {model.access_methods &&
                      model.access_methods.length > 0 ? (
                        <List dense disablePadding>
                          {model.access_methods.map((method, index) => (
                            <ListItem
                              key={index}
                              disablePadding
                              sx={{ py: 0.5 }}
                            >
                              <ListItemText primary={method} />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          Access methods not specified.
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h6"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 2,
                        }}
                      >
                        <AttachMoneyIcon /> Subscription Options
                      </Typography>

                      {model.subscriptions && model.subscriptions.length > 0 ? (
                        <List dense disablePadding>
                          {model.subscriptions.map((sub, index) => (
                            <ListItem
                              key={index}
                              disablePadding
                              sx={{ py: 0.5 }}
                            >
                              <ListItemText primary={sub} />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          Subscription options not specified.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            {/* Applications */}
            {model.applications && model.applications.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Applications
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {model.applications.map((app, index) => (
                    <Chip key={index} label={app.name} variant="outlined" />
                  ))}
                </Box>
              </Box>
            )}

            {/* External Link */}
            {model.link && (
              <Box sx={{ mt: 4, display: "flex" }}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<LaunchIcon />}
                  href={model.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    backgroundColor: tertColor,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: tertHoverColor,
                      color: "#fff",
                    },
                  }}
                >
                  Visit Official Website
                </Button>
              </Box>
            )}

            {/* Last Updated */}
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 1,
              }}
            >
              <ScheduleIcon fontSize="small" color="action" />
              <Typography variant="caption" color="text.secondary">
                Last updated: {formatDate(model.last_update)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Button
          component={RouterLink}
          to="/ai-models"
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          sx={{ mb: 3 }}
        >
          Back to AI Models
        </Button>
      </Box>
    </Container>
  );
};

export default KthAiModelDetail;
