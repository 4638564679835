import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
  Paper,
  Divider,
  Chip,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import StorageIcon from "@mui/icons-material/Storage";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CodeIcon from "@mui/icons-material/Code";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomAccordion from "../common/CustomAccordion";
import LaunchIcon from "@mui/icons-material/Launch";
import EmbededContactForm from "./EmbededContactForm";
import { getImageUrl } from "../../utils/helpers";
import SetDocumentMeta from "../common/SetDocumentMeta";

// Define "Jump to Section" items with icons
export const jumpToSectionAll = [
  {
    id: "ai-saas-tools",
    label: "AI SaaS Agreements",
    icon: <CloudQueueIcon sx={{ mr: 1 }} />,
  },
  {
    id: "ai-data-agreements",
    label: "Data & Training Agreements",
    icon: <StorageIcon sx={{ mr: 1 }} />,
  },
  {
    id: "ai-model-licensing",
    label: "AI Model Licensing",
    icon: <SmartToyIcon sx={{ mr: 1 }} />,
  },
  {
    id: "ai-development-agreements",
    label: "AI Development Agreements",
    icon: <CodeIcon sx={{ mr: 1 }} />,
  },
  {
    id: "ai-consulting-agreements",
    label: "AI Consulting Agreements",
    icon: <LightbulbIcon sx={{ mr: 1 }} />,
  },
  {
    id: "tech-transfer",
    label: "AI Tech Transfer & IP",
    icon: <CompareArrowsIcon sx={{ mr: 1 }} />,
  },
];

export const jumpToSectionStartup = [
  {
    id: "founder-ip",
    label: "Founder & IP Assignments",
    icon: <AssignmentIndIcon sx={{ mr: 1 }} />,
  },
  {
    id: "investor-terms",
    label: "Investor & Funding Terms",
    icon: <MonetizationOnIcon sx={{ mr: 1 }} />,
  },
  {
    id: "vendor-partnership",
    label: "Vendor & Partnership Deals",
    icon: <HandshakeIcon sx={{ mr: 1 }} />,
  },
];

// InfoBox component for highlighted information
const InfoBox = ({ title, content, type = "info", icon }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getIcon = () => {
    if (icon) return icon;
    switch (type) {
      case "warning":
        return <WarningIcon />;
      case "success":
        return <CheckCircleOutlineIcon />;
      default:
        return <InfoIcon />;
    }
  };

  const getBackgroundColor = () => {
    switch (type) {
      case "warning":
        return "#fff3e0";
      case "success":
        return "#e8f5e9";
      case "primary":
        return "#e3f2fd";
      default:
        return "#f5f5f5";
    }
  };

  const getBorderColor = () => {
    switch (type) {
      case "warning":
        return "#ffb74d";
      case "success":
        return "#81c784";
      case "primary":
        return "#90caf9";
      default:
        return "#e0e0e0";
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        my: 2,
        backgroundColor: getBackgroundColor(),
        borderLeft: `4px solid ${getBorderColor()}`,
        borderRadius: 1,
      }}
    >
      <Box display="flex" alignItems="flex-start">
        <Box
          sx={{
            mr: 2,
            color: getBorderColor(),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 0.5,
          }}
        >
          {getIcon()}
        </Box>
        <Box>
          {title && (
            <Typography
              variant={isMobile ? "subtitle1" : "h6"}
              fontWeight="medium"
              gutterBottom
            >
              {title}
            </Typography>
          )}
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {content}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

// Feature card component for displaying key features or services
const FeatureCard = ({ title, icon, content, color = "#4ABDAC" }) => {
  return (
    <Card
      elevation={1}
      sx={{
        height: "100%",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
        },
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={1}>
          <Box sx={{ color, mr: 1.5 }}>{icon}</Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color, fontWeight: "medium" }}
          >
            {title}
          </Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body2">{content}</Typography>
      </CardContent>
    </Card>
  );
};

const AiAgreements = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    secColor,
    tertColor,
    tertHoverColor,
    quadColor,
    sextColor,
  } = baseConfig.siteBranding;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  SetDocumentMeta({
    title: "Legal Agreements for AI: Expert Attorney Counseling",
    description:
      "Expert legal services for AI businesses. We provide tailored AI contracts and agreements for AI startups and established companies, covering IP, funding, partnerships, and compliance. Schedule your free consultation today.",
    keywords:
      "AI Attorney Arizona, \
      AI legal agreements, \
      AI contracts, \
      AI lawyer, \
      AI SaaS agreements, \
      AI model licensing agreement, \
      AI data agreement, \
      AI training agreement, \
      AI development agreement, \
      AI consulting agreement, \
      AI startup legal, \
      Founder IP assignment, \
      Investor terms AI, \
      Vendor partnership AI, \
      Technology transfer AI, \
      Intellectual property AI, \
      Drafting AI agreements, \
      Review AI agreements",
  });

  // Custom styles
  const sectionStyles = {
    header: {
      py: 3,
      px: isMobile ? 2 : 4,
      mb: 4,
      borderRadius: 2,
      backgroundImage: `linear-gradient(135deg, ${tertColor}22 0%, ${tertColor}44 100%)`,
      boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
    },
    sectionTitle: {
      px: 2,
      py: 1.5,
      mb: 2,
      borderRadius: 1,
      backgroundColor: tertColor,
      color: "white",
      fontWeight: "medium",
      display: "flex",
      alignItems: "center",
    },
    card: {
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
      borderRadius: 2,
      overflow: "hidden",
      transition: "all 0.3s ease",
      "&:hover": {
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
        transform: "translateY(-2px)",
      },
    },
    accordionTitle: {
      fontWeight: "medium",
      color: "text.primary",
    },
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container sx={{ py: 4 }} className="link-no-decoration">
      {/* ======== PAGE HEADER ======== */}
      <Box component="header" sx={sectionStyles.header}>
        {/* Banner / Headline */}
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant={isMobile ? "h4" : "h3"}
            fontWeight="bold"
            gutterBottom
            color="text.primary"
          >
            Legal Agreements for AI
          </Typography>
          <Typography
            variant="h6"
            fontWeight="medium"
            color="text.secondary"
            gutterBottom
          >
            Expert Attorney Drafting and Review for Your AI Business
          </Typography>
          <Divider
            sx={{ my: 2, width: "20%", mx: "auto", borderColor: tertColor }}
          />
        </Box>

        {/* Description */}
        <Typography
          variant="body1"
          sx={{
            mt: 3,
            lineHeight: 1.8,
            textAlign: "center",
            maxWidth: "85%",
            mx: "auto",
            fontSize: "1.1rem",
          }}
        >
          <strong>Need expert legal assistance with your AI venture?</strong> We
          specialize in drafting and reviewing tailored legal agreements for a
          wide range of AI-based businesses. Whether you're developing
          innovative AI SaaS tools, licensing sophisticated AI models, managing
          crucial AI training data, commissioning custom AI solutions, or
          navigating AI consulting, we ensure clarity on ownership, data rights,
          liability limitations, and regulatory compliance.
        </Typography>
      </Box>

      {/* Note with MUI Alert Box */}
      <Alert severity="info" sx={{ mt: 2, borderRadius: 2 }}>
        <Typography variant="body1">
          <strong>Important Note:</strong> This page focuses on legal agreements
          for AI products and services prepared by a human attorney – we do not
          provide AI-generated legal documents.
        </Typography>
      </Alert>

      {/* Banner below Alert for scheduling */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#e0f7fa",
          p: 2,
          borderRadius: 2,
          mt: 2,
          mb: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          // Scroll to the contact/CTA section
          const ctaSection = document.getElementById("contact-section");
          if (ctaSection) {
            ctaSection.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        <PhoneInTalkIcon sx={{ mr: 1 }} />

        <Typography variant="body1">
          <strong>Schedule a Free Consultation</strong> to discuss your AI legal
          needs!
        </Typography>
      </Box>

      {/* ======== TABLE OF CONTENTS ======== */}
      <Card variant="outlined" sx={{ ...sectionStyles.card, mb: 4 }}>
        <CardContent sx={{ p: isMobile ? 2 : 3 }}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight="medium"
            sx={{
              color: tertColor,
              borderBottom: `2px solid ${tertColor}`,
              pb: 1,
            }}
          >
            Quick Navigation
          </Typography>

          <Grid container spacing={3}>
            {/* Left column: "Agreements for All" */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
                sx={{ pt: 1 }}
              >
                AI Legal Agreements for All Organizations
              </Typography>
              <List dense disablePadding>
                {jumpToSectionAll.map((section) => (
                  <ListItem key={section.id} disablePadding>
                    <ListItemButton
                      onClick={() => scrollToSection(section.id)}
                      sx={{
                        borderRadius: 1,
                        mb: 0.5,
                        transition: "all 0.2s ease",
                        "&:hover": {
                          backgroundColor: `${tertColor}22`,
                          pl: 2,
                        },
                      }}
                    >
                      <Box sx={{ color: tertColor }}>{section.icon}</Box>
                      <ListItemText primary={section.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>

            {/* Right column: "Agreements for AI Startups" */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
                sx={{ pt: 1 }}
              >
                Specialized AI Agreements for Startups
              </Typography>
              <List dense disablePadding>
                {jumpToSectionStartup.map((section) => (
                  <ListItem key={section.id} disablePadding>
                    <ListItemButton
                      onClick={() => scrollToSection(section.id)}
                      sx={{
                        borderRadius: 1,
                        mb: 0.5,
                        transition: "all 0.2s ease",
                        "&:hover": {
                          backgroundColor: `${priColor}22`,
                          pl: 2,
                        },
                      }}
                    >
                      <Box sx={{ color: priColor }}>{section.icon}</Box>
                      <ListItemText primary={section.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* ======== MAIN CONTENT SECTIONS ======== */}
      <Box mb={6}>
        {/* Divider with Text */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 2,
          }}
        >
          <Divider sx={{ flex: 1 }} />
          <Box
            sx={{
              px: 2,
              color: tertColor,
              display: "flex",
              alignItems: "center",
            }}
          >
            AI Legal Agreements for All Organizations
          </Box>
          <Divider sx={{ flex: 1 }} />
        </Box>

        {/* 1. AI SaaS Tools & Applications */}
        <Box id="ai-saas-tools" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${tertColor}22`,
              }}
            >
              <CloudQueueIcon sx={{ mr: 2, color: tertColor }} />
              <Typography variant="h6" fontWeight="bold">
                Agreements for AI SaaS Applications
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                Developing or deploying AI-powered Software as a Service (SaaS)
                solutions? Our legal experts provide comprehensive assistance in
                drafting, reviewing, and revising your agreements.
              </Typography>

              <InfoBox
                title="What We Cover"
                content="We ensure your legal framework aligns with evolving regulatory requirements, implements robust risk mitigation strategies, and ensures compliance with third-party AI model provider terms."
                type="primary"
              />

              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      height: "100%",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #e0e0e0",
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      SaaS Subscription Agreements
                    </Typography>
                    <Typography variant="body2">
                      These agreements define critical aspects such as service
                      terms, user rights, limitations of liability, and data
                      privacy policies. For platforms offering decision-making
                      tools, we ensure explicit disclaimers clarify that the
                      tool's output does not constitute regulated professional
                      advice.
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      height: "100%",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #e0e0e0",
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      EULAs, Terms of Use & Privacy Policies
                    </Typography>
                    <Typography variant="body2">
                      These documents outline acceptable uses of the AI service,
                      detail data handling practices, and clarify intellectual
                      property ownership. We incorporate regulatory language
                      relevant to your industry.
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      height: "100%",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #e0e0e0",
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      AI-Specific Disclaimers & Risk Mitigation
                    </Typography>
                    <Typography variant="body2">
                      Clear disclaimers communicate that AI-generated content is
                      for informational purposes only. Indemnification clauses
                      protect against potential liability from errors in
                      automated decision-making or misleading predictions.
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      height: "100%",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #e0e0e0",
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      API Integration & Foundation Model Terms
                    </Typography>
                    <Typography variant="body2">
                      For SaaS platforms leveraging third-party foundation
                      models (OpenAI, Anthropic, Google, etc.), we seamlessly
                      integrate the API provider's terms of service into your
                      agreements, including compliance with data usage policies.
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      height: "100%",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #e0e0e0",
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                    >
                      Data Sharing and Marketing Consent Agreements
                    </Typography>
                    <Typography variant="body2">
                      If your AI SaaS platform intends to share user data with
                      external entities, we implement explicit and informed user
                      consent mechanisms to adhere to data privacy regulations
                      like CCPA, GDPR, and FTC guidelines.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {/* 2. AI Data & Training */}
        <Box id="ai-data-agreements" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${tertColor}22`,
              }}
            >
              <StorageIcon sx={{ mr: 2, color: tertColor }} />
              <Typography variant="h6" fontWeight="bold">
                AI Data & Training Agreements
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                The performance of AI models is intrinsically linked to the
                quality and legality of the data they are trained on. Our firm
                specializes in preparing custom-tailored AI data use agreements
                that address data licensing, copyright considerations, and the
                unique legal aspects of agreements involving human-labeled data.
              </Typography>

              <Box sx={{ my: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Card elevation={1} sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ color: tertColor, fontWeight: "medium" }}
                        >
                          Data Licensing Agreements
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body2">
                          Ensure compliance with privacy regulations when
                          acquiring data from third-party providers. Define
                          usage rights, AI training activities, storage
                          limitations, and indemnification clauses.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card elevation={1} sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ color: tertColor, fontWeight: "medium" }}
                        >
                          Data Collaboration & Sharing
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body2">
                          Govern shared use of datasets when collaborating with
                          academic institutions or other companies. Specify
                          attribution requirements and define rights to trained
                          AI models.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card elevation={1} sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ color: tertColor, fontWeight: "medium" }}
                        >
                          Human Labeling & Annotation
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body2">
                          Establish ownership of labeled data and IP rights when
                          engaging human annotators, data labelers, or
                          reviewers. Define confidentiality obligations and
                          compensation terms.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>

              <InfoBox
                title="Adapting to the Evolving Legal Landscape"
                content="The legal framework surrounding AI training data is still developing in many jurisdictions. Our team stays abreast of the most current legal standards to ensure your AI data agreements are robust and legally defensible."
                type="primary"
              />

              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  backgroundColor: "#f5f5f5",
                  borderRadius: 2,
                  textAlign: "center",
                  border: `1px solid ${tertColor}`,
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color={tertColor}
                >
                  Seeking legal expertise for AI training data licensing or
                  human labeling agreements?
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  We provide counsel to ensure legally sound contracts for every
                  stage of the AI data pipeline – from initial data acquisition
                  to intricate human-in-the-loop processes.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* 3. AI Model Licensing */}
        <Box id="ai-model-licensing" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${tertColor}22`,
              }}
            >
              <SmartToyIcon sx={{ mr: 2, color: tertColor }} />
              <Typography variant="h6" fontWeight="bold">
                AI Model Licensing Agreements
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                Licensing AI models presents various scenarios, each requiring
                careful legal consideration to protect intellectual property and
                define usage rights.
              </Typography>

              <InfoBox
                title="Why Model Licensing Agreements Matter"
                content="Well-drafted agreements safeguard your core intellectual property, clearly define the boundaries of permitted use, and establish a framework for revenue generation and liability allocation. See below for common types of AI model licensing agreements."
                type="primary"
              />

              <Box sx={{ my: 3 }}>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        border: `1px solid ${tertColor}33`,
                        borderLeft: `4px solid ${tertColor}`,
                        borderRadius: 1,
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Inbound Licensing for Internal Use
                      </Typography>
                      <Typography variant="body2">
                        Obtaining a license to use a pre-trained AI model for
                        internal research, development, testing, or operational
                        efficiency. These agreements define the scope of
                        permitted internal use and specify ownership of
                        improvements or derivative works.
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        border: `1px solid ${tertColor}33`,
                        borderLeft: `4px solid ${tertColor}`,
                        borderRadius: 1,
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Inbound Licensing for Commercial Products
                      </Typography>
                      <Typography variant="body2">
                        Integrating a licensed AI model into your commercial
                        product or service. The agreement addresses downstream
                        distribution rights, royalty models, and IP ownership of
                        customizations you create.
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        border: `1px solid ${tertColor}33`,
                        borderLeft: `4px solid ${tertColor}`,
                        borderRadius: 1,
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Outbound Licensing for Internal Use
                      </Typography>
                      <Typography variant="body2">
                        Licensing your proprietary AI model to another
                        organization for their internal deployment. These
                        agreements define permitted internal uses,
                        geographical/user limitations, and treatment of
                        derivative data.
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        border: `1px solid ${tertColor}33`,
                        borderLeft: `4px solid ${tertColor}`,
                        borderRadius: 1,
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        gutterBottom
                      >
                        Outbound Licensing for Commercial Products
                      </Typography>
                      <Typography variant="body2">
                        Granting another party the right to integrate your AI
                        model into their commercial offerings. Includes scope of
                        use, royalties, brand usage guidelines, and performance
                        warranties.
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>

              <InfoBox
                title="Key Provisions: Limitations on Liability and Performance"
                content="All AI model licenses should include clear provisions governing expected accuracy, disclaimers regarding fitness for purpose, and limitations on liability. This is especially important given AI models' potential to produce inaccurate outputs or exhibit biases."
                type="warning"
                icon={<WarningIcon />}
              />
            </CardContent>
          </Card>
        </Box>

        {/* 4. AI Development Agreements */}
        <Box id="ai-development-agreements" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${tertColor}22`,
              }}
            >
              <CodeIcon sx={{ mr: 2, color: tertColor }} />
              <Typography variant="h6" fontWeight="bold">
                AI Development Agreements
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                If you are commissioning the development of a custom AI model,
                application, or system from a third-party developer or AI lab, a
                well-defined AI Development Agreement is essential to protect
                your interests.
              </Typography>

              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                      sx={{ color: tertColor }}
                    >
                      Key Legal Considerations
                    </Typography>
                    <List dense>
                      {[
                        "Scope of Work and Specifications",
                        "Intellectual Property Ownership",
                        "Deliverables and Timelines",
                        "Payment Terms",
                        "Confidentiality",
                      ].map((item, index) => (
                        <ListItem key={index} sx={{ py: 0.5 }}>
                          <ChevronRightIcon sx={{ color: tertColor, mr: 1 }} />
                          <ListItemText
                            primary={
                              <Typography variant="body2" fontWeight="medium">
                                {item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      gutterBottom
                      sx={{ color: tertColor }}
                    >
                      Essential Protections
                    </Typography>
                    <List dense>
                      {[
                        "Warranties and Performance Standards",
                        "Acceptance Testing",
                        "Maintenance and Support",
                        "Limitation of Liability",
                        "Indemnification",
                      ].map((item, index) => (
                        <ListItem key={index} sx={{ py: 0.5 }}>
                          <ChevronRightIcon sx={{ color: tertColor, mr: 1 }} />
                          <ListItemText
                            primary={
                              <Typography variant="body2" fontWeight="medium">
                                {item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              </Grid>

              <InfoBox
                content="We can help you craft comprehensive AI Development Agreements that address these critical aspects and safeguard your investment in custom AI solutions."
                type="success"
                icon={<CheckCircleOutlineIcon />}
              />
            </CardContent>
          </Card>
        </Box>

        {/* 5. AI Consulting Agreements */}
        <Box id="ai-consulting-agreements" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${tertColor}22`,
              }}
            >
              <LightbulbIcon sx={{ mr: 2, color: tertColor }} />
              <Typography variant="h6" fontWeight="bold">
                AI Consulting Agreements: Expert Guidance for Your AI Strategy
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                Engaging AI consultants for strategic advice, technical
                expertise, or project implementation requires a clear consulting
                agreement. These agreements define the scope of services,
                deliverables, timelines, and intellectual property rights.
              </Typography>

              <Grid container spacing={3} sx={{ mt: 1 }}>
                {[
                  {
                    title: "Scope of Services",
                    content:
                      "A detailed description of the consulting services to be provided, including specific tasks, objectives, and expected outcomes.",
                  },
                  {
                    title: "Deliverables",
                    content:
                      "Identification of any tangible deliverables, such as reports, strategies, code, or models, that the consultant will provide.",
                  },
                  {
                    title: "Project Timeline",
                    content:
                      "Establishing a clear timeline with key milestones and deadlines for the consulting engagement.",
                  },
                  {
                    title: "Fees and Payment Terms",
                    content:
                      "Specifying the consultant's fees (e.g., hourly rates, fixed fees), payment schedule, and any reimbursable expenses.",
                  },
                ].map((item, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 2,
                        height: "100%",
                        backgroundColor: "#f9f9f9",
                        border: "1px solid #e0e0e0",
                        borderRadius: 2,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body2">{item.content}</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>

              <Typography
                variant="subtitle1"
                fontWeight="bold"
                gutterBottom
                sx={{ mt: 3, color: tertColor }}
              >
                Additional Key Elements
              </Typography>

              <Grid container spacing={3}>
                {[
                  {
                    title: "Intellectual Property Rights",
                    content:
                      "Clearly define who owns the IP rights to any work product created during the consulting engagement. This is particularly important if the consultant is developing custom AI solutions.",
                  },
                  {
                    title: "Confidentiality",
                    content:
                      "Protect your sensitive business information and any proprietary AI technologies with robust confidentiality clauses.",
                  },
                  {
                    title: "Non-Solicitation and Non-Compete",
                    content:
                      "Depending on the engagement, you might consider including clauses that restrict the consultant from soliciting your employees or competing with your business.",
                  },
                  {
                    title: "Termination Clause",
                    content:
                      "Define the conditions under which either party can terminate the consulting agreement to protect your interests.",
                  },
                ].map((item, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box sx={{ display: "flex", mb: 2 }}>
                      <ChevronRightIcon
                        sx={{ color: tertColor, mt: 0.5, mr: 1, flexShrink: 0 }}
                      />
                      <Box>
                        <Typography variant="subtitle2" fontWeight="bold">
                          {item.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item.content}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <InfoBox
                title="AI Consulting Agreement Legal Assistance"
                content="Our firm can help you draft comprehensive AI Consulting
                  Agreements that clearly define the relationship between your
                  organization and your AI consultants."
                type="primary"
              />
            </CardContent>
          </Card>
        </Box>

        {/* 6. Tech Transfer & Cross-Reference to Patent Practice */}
        <Box id="tech-transfer" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${tertColor}22`,
              }}
            >
              <CompareArrowsIcon sx={{ mr: 2, color: tertColor }} />
              <Typography variant="h6" fontWeight="bold">
                AI Tech Transfer & Intellectual Property Agreements
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                The development and commercialization of AI systems often
                involve complex intellectual property landscapes. Technology
                transfer agreements are crucial for defining how IP rights are
                moved, shared, or commercialized.
              </Typography>

              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <FeatureCard
                    title="Master License Agreements"
                    icon={<CompareArrowsIcon />}
                    content="These agreements grant a party the right to use specific AI software, libraries, or underlying technology under clearly defined terms and conditions. Frequently utilized when a large enterprise licenses core AI technology from a startup or vice versa."
                    color={tertColor}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeatureCard
                    title="IP Assignment Agreements"
                    icon={<AssignmentIndIcon />}
                    content="These legally transfer the ownership of intellectual property assets, such as patents, source code, or proprietary AI algorithms, from one party to another. Common in acquisitions of AI startups or formal transfers of founder technology."
                    color={tertColor}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeatureCard
                    title="Joint Development Agreements"
                    icon={<HandshakeIcon />}
                    content="When collaborating on AI research or development, these agreements outline the contributions of each party, the ownership of any newly created IP, and the terms for its commercialization."
                    color={tertColor}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeatureCard
                    title="Beta Testing and Pilot Agreements"
                    icon={<CodeIcon />}
                    content="For organizations releasing new AI features or products, these agreements govern the terms under which select users can test the technology and provide feedback, including confidentiality and liability clauses."
                    color={tertColor}
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  backgroundColor: "#f5f5f5",
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="body1" sx={{ mb: isMobile ? 2 : 0 }}>
                  Beyond these agreements, we also provide comprehensive support
                  for patent filings to protect your AI inventions.
                </Typography>

                <Button
                  startIcon={<LaunchIcon />}
                  variant="contained"
                  sx={{
                    mt: 2,
                    ml: 3,
                    backgroundColor: tertColor,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: tertHoverColor,
                      color: "#fff",
                    },
                  }}
                  component={RouterLink}
                  to="/patents"
                >
                  Learn More About Patents
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>

      {/* ======== AI STARTUP-SPECIFIC SECTIONS ======== */}
      <Box mb={6}>
        {/* Divider with Text */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 2,
          }}
        >
          <Divider sx={{ flex: 1 }} />
          <Box
            sx={{
              px: 2,
              color: tertColor,
              display: "flex",
              alignItems: "center",
            }}
          >
            Specialized AI Agreements for Startups
          </Box>
          <Divider sx={{ flex: 1 }} />
        </Box>

        {/* 1. Founder IP Assignments */}
        <Box id="founder-ip" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${priColor}22`,
              }}
            >
              <AssignmentIndIcon sx={{ mr: 2, color: priColor }} />
              <Typography variant="h6" fontWeight="bold">
                Founder & Intellectual Property Assignments for AI Startups
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                For early-stage AI startups, clearly establishing ownership of
                the core intellectual property is paramount. This is especially
                true when founders or initial contributors have developed key
                code or AI models prior to the company's formal incorporation.
              </Typography>

              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2.5,
                      borderRadius: 2,
                      backgroundColor: "#f5f5f5",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: priColor }}
                    >
                      The Importance of Clear Assignment
                    </Typography>
                    <Typography variant="body2" paragraph>
                      Investors typically require concrete proof that all
                      critical intellectual property assets are legally assigned
                      to the startup entity. This is essential to prevent future
                      disputes or potential roadblocks, particularly if a
                      founder departs from the company.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      p: 2.5,
                      borderRadius: 2,
                      backgroundColor: "#f5f5f5",
                      height: "100%",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: priColor }}
                    >
                      Key Terms in Assignment Agreements
                    </Typography>
                    <Typography variant="body2" paragraph>
                      These agreements explicitly define which assets are being
                      assigned to the startup, including source code, AI
                      algorithms, training datasets, and any related
                      documentation. They may also address any potential
                      royalties or ongoing obligations from the startup to the
                      assigning founder(s).
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <InfoBox
                content="Executing proper founder IP assignment agreements early on provides a solid legal foundation for future investment rounds, licensing opportunities, or potential acquisition of your AI technology."
                type="success"
                icon={<CheckCircleOutlineIcon />}
              />
            </CardContent>
          </Card>
        </Box>

        {/* 2. Investor & Funding Terms */}
        <Box id="investor-terms" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${priColor}22`,
              }}
            >
              <MonetizationOnIcon sx={{ mr: 2, color: priColor }} />
              <Typography variant="h6" fontWeight="bold">
                Investor & Funding Agreements for AI Startups
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                Many AI startups rely on external funding to fuel their growth
                and scale their innovative technologies. Common funding
                agreements include seed investment notes, convertible notes,
                Simple Agreements for Future Equity (SAFEs), as well as
                comprehensive shareholder or investor rights agreements.
              </Typography>

              <Box
                sx={{
                  p: 2.5,
                  my: 2,
                  borderRadius: 2,
                  backgroundColor: "#f9f9f9",
                  border: `1px solid ${priColor}33`,
                  borderLeft: `6px solid ${priColor}`,
                }}
              >
                <Typography variant="h6" gutterBottom sx={{ color: priColor }}>
                  AI-Specific Considerations for Investors
                </Typography>
                <Typography variant="body2" paragraph>
                  AI startups often possess significant intangible assets, such
                  as proprietary AI models and unique datasets, which can be
                  challenging to value accurately. Investors may seek specific
                  warranties and representations regarding:
                </Typography>
                <List dense>
                  {[
                    "Data provenance and compliance with data privacy regulations",
                    "Accuracy and reliability of AI models",
                    "Ownership of training data and algorithms",
                    "Compliance with relevant industry-specific regulations for AI-driven products",
                    "Potential risks related to AI model bias or limitations",
                  ].map((item, index) => (
                    <ListItem key={index} sx={{ py: 0.5 }}>
                      <ChevronRightIcon sx={{ color: priColor, mr: 1 }} />
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </Box>

              <InfoBox
                content="Properly structuring investor and funding agreements is crucial
                for clearly defining equity ownership, outlining board
                representation, and establishing the rights and obligations
                related to the core AI intellectual property that underpins your
                company's valuation."
                type="success"
                icon={<CheckCircleOutlineIcon />}
              />
            </CardContent>
          </Card>
        </Box>

        {/* 3. Vendor & Partnership Deals */}
        <Box id="vendor-partnership" mb={4}>
          <Card sx={{ ...sectionStyles.card, mb: 2 }}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: `${priColor}22`,
              }}
            >
              <HandshakeIcon sx={{ mr: 2, color: priColor }} />
              <Typography variant="h6" fontWeight="bold">
                Vendor & Partnership Agreements for AI Startups
              </Typography>
            </Box>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="body1" paragraph>
                AI startups often need to outsource specific tasks such as model
                training, data labeling, or specialized AI development to
                external vendors. In these situations, robust vendor agreements
                are essential to protect your confidential information and
                ensure that the intellectual property rights to any developed
                technology remain with your startup.
              </Typography>

              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <Card elevation={2} sx={{ height: "100%" }}>
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={1}>
                        <HandshakeIcon sx={{ color: priColor, mr: 1.5 }} />
                        <Typography variant="h6" gutterBottom fontWeight="bold">
                          Strategic Partner Collaborations
                        </Typography>
                      </Box>
                      <Divider sx={{ mb: 2 }} />
                      <Typography variant="body2" paragraph>
                        When AI startups collaborate with larger enterprises or
                        academic research labs to co-develop innovative AI
                        solutions, it is vital to have well-defined partnership
                        agreements. These agreements should:
                      </Typography>
                      <List dense>
                        {[
                          "Clearly outline the responsibilities and contributions of each party",
                          "Specify the ownership or licensing terms for any newly created intellectual property",
                          "Define the rights for commercial distribution of the jointly developed AI technologies",
                          "Include provisions for confidentiality and data protection",
                          "Establish clear terms for project termination and post-termination rights",
                        ].map((item, index) => (
                          <ListItem key={index} sx={{ py: 0.5 }}>
                            <ChevronRightIcon sx={{ color: priColor, mr: 1 }} />
                            <ListItemText primary={item} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card elevation={2} sx={{ height: "100%" }}>
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={1}>
                        <StorageIcon sx={{ color: priColor, mr: 1.5 }} />
                        <Typography variant="h6" gutterBottom fontWeight="bold">
                          Vendor Agreements
                        </Typography>
                      </Box>
                      <Divider sx={{ mb: 2 }} />
                      <Typography variant="body2" paragraph>
                        When outsourcing specific AI tasks or services to
                        external vendors, your agreements should focus on:
                      </Typography>
                      <List dense>
                        {[
                          "Clear definition of the scope of work and deliverables",
                          "Explicit IP ownership provisions ensuring your startup retains rights to all work product",
                          "Vendor warranties regarding their technical capabilities and compliance with relevant regulations",
                          "Data handling and security protocols, especially for sensitive or regulated data",
                          "Quality control measures and acceptance testing processes",
                        ].map((item, index) => (
                          <ListItem key={index} sx={{ py: 0.5 }}>
                            <ChevronRightIcon sx={{ color: priColor, mr: 1 }} />
                            <ListItemText primary={item} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <InfoBox
                content="These types of agreements enable AI startups to effectively leverage external expertise and resources without compromising their future commercialization prospects or intellectual property assets."
                type="primary"
                icon={<InfoIcon />}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>

      {/* ======== WHY WORK WITH US SECTION ======== */}
      <Box id="why-work-with-us" mb={4}>
        <Card sx={{ ...sectionStyles.card, mb: 2 }}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              backgroundColor: "#6c757d",
              color: "white",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              Why Choose Our AI Legal Expertise?
            </Typography>
          </Box>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color={tertColor}
                  >
                    Technical Proficiency
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Our firm is built on a foundation of engineering and
                    technology law expertise, giving us a significant advantage
                    in the complex field of AI and machine learning. Unlike
                    traditional law firms, we possess a deep understanding of
                    the technical intricacies of AI, including training data
                    methodologies, model architectures, and algorithmic design.
                  </Typography>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color={tertColor}
                  >
                    Real-World AI Industry Experience
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Our team regularly advises clients on the practical
                    deployment of AI across diverse industries, including SaaS,
                    healthcare, finance, unmanned aerial systems (UAS), wireless
                    communications, and the Internet of Things (IoT). This
                    hands-on experience enables us to anticipate potential legal
                    challenges.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color={tertColor}
                  >
                    Comprehensive Intellectual Property Strategies
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Our services extend beyond just drafting contracts. We also
                    provide comprehensive support for patent filings,
                    intellectual property monetization strategies, licensing
                    agreements, and technology transfer. This holistic approach
                    ensures a consistent and synergistic legal strategy for your
                    AI innovations.
                  </Typography>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color={tertColor}
                  >
                    Staying Ahead of the Curve in AI Law
                  </Typography>
                  <Typography variant="body2" paragraph>
                    The legal and regulatory landscape surrounding artificial
                    intelligence is constantly evolving. Our team is committed
                    to staying informed about the latest developments in AI
                    regulations, copyright law as it pertains to AI-generated
                    content, and data privacy laws.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      {/* ======== FAQ SECTION ======== */}
      <Box id="faq-section" mb={4}>
        <Card sx={{ ...sectionStyles.card, mb: 2 }}>
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="text.primary">
              Frequently Asked Questions About AI Legal Agreements
            </Typography>
          </Box>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={4}>
              {[
                {
                  question:
                    "Are the AI agreements you provide generated by AI?",
                  answer:
                    "No, all of our AI agreements are individually professionally drafted by an experienced human attorney. We do not utilize automated AI tools to generate your legal documents, ensuring personalized and expert legal counsel.",
                },
                {
                  question:
                    "Can we simply use standard tech contract templates for AI agreements?",
                  answer:
                    "While general technology contract templates can provide a starting point, AI-specific legal and technical considerations, such as data usage rights, ownership of model improvements, unique intellectual property issues related to AI algorithms, and regulatory compliance for AI applications, often necessitate tailored terms and conditions. Using a generic template may leave you exposed to significant legal risks.",
                },
                {
                  question:
                    "Is it necessary to obtain a patent for our AI model?",
                  answer:
                    "Obtaining patent protection can be a crucial step in safeguarding your unique AI methods, architectures, or novel training techniques. We offer comprehensive AI patent filing, prosecution, and licensing support to help you protect your valuable AI innovations.",
                },
                {
                  question:
                    "Are the AI agreements you draft suitable for large enterprise-level deployments?",
                  answer:
                    "Yes. Our firm has experience in handling contracts for a wide range of clients, from early-stage startups to large established corporations. We ensure that each agreement is appropriately scaled and addresses the specific compliance, liability, and other considerations relevant to enterprise-level AI deployments.",
                },
                {
                  question:
                    "What are some of the key legal risks associated with AI that these agreements help address?",
                  answer:
                    "Our AI agreements are designed to address critical legal risks such as intellectual property infringement, data privacy violations, bias in AI algorithms, inaccurate or misleading AI outputs, lack of transparency, and non-compliance with industry-specific regulations.",
                },
              ].map((faq, index) => (
                <Grid item xs={12} key={index}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                      borderRadius: 2,
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="medium"
                      gutterBottom
                      color={tertColor}
                    >
                      {faq.question}
                    </Typography>
                    <Typography variant="body2">{faq.answer}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>

      {/* ======== CONTACT / CTA SECTION ======== */}
      <Box
        component="section"
        id="contact-section"
        sx={{
          mt: 0,
          p: 4,
          borderRadius: 2,
          backgroundColor: "#f3f6f9",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
          border: `1px solid ${tertColor}33`,
        }}
      >
        {/* Title spanning both columns */}
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" fontWeight="bold" color={tertColor}>
            Ready to Discuss Your AI Agreement Needs?
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ mt: 1, fontSize: "1.1rem" }}
          >
            For Immediate Assistance, Call Us At:{" "}
            <Chip
              label="+1-602-837-0631"
              color="primary"
              sx={{
                backgroundColor: tertColor,
                fontWeight: "bold",
                fontSize: "1rem",
                height: "32px",
                "& .MuiChip-label": {
                  color: "white",
                },
              }}
            />
          </Typography>
        </Box>

        <Grid container spacing={4} alignItems="flex-start">
          {/* LEFT SIDE: Image + Overview */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              justifyContent="center"
              mb={3}
              sx={{
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 2,
                  boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
                  pointerEvents: "none",
                },
              }}
            >
              <img
                src={getImageUrl("ai-agreements-banner-600x400.png")}
                alt="AI Legal Agreements Drafting Consultation"
                style={{
                  width: "100%",
                  maxWidth: "600px",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            </Box>

            <Paper
              elevation={0}
              sx={{ p: 2, backgroundColor: "white", borderRadius: 2 }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ color: tertColor }}
              >
                What to Expect During Your Free AI Legal Consultation:
              </Typography>

              <List>
                {[
                  "We will thoroughly assess your specific AI legal requirements and gather necessary information for a conflict of interest check.",
                  "We will provide you with a preliminary cost estimate and projected timeline for the required legal services.",
                ].map((text, index) => (
                  <ListItem key={index} sx={{ py: 1 }}>
                    <CheckCircleOutlineIcon
                      sx={{ color: tertColor, mr: 1.5 }}
                    />
                    <ListItemText
                      primary={
                        <Typography variant="body2" fontWeight="medium">
                          {text}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>

              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: 1,
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color={tertColor}
                  >
                    For AI Startups and Emerging AI Tools
                  </Typography>
                  <List dense>
                    {[
                      "We will discuss the fundamentals of your AI solution and its current stage of development.",
                      "We will outline the specific types of legal documents that are likely to be essential for your business.",
                    ].map((text, idx) => (
                      <ListItem key={idx} sx={{ py: 0.5 }}>
                        <ChevronRightIcon
                          sx={{ fontSize: 16, color: tertColor, mr: 1 }}
                        />
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Box sx={{ p: 2, backgroundColor: "#f9f9f9", borderRadius: 1 }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color={tertColor}
                  >
                    For Established AI Businesses
                  </Typography>
                  <List dense>
                    {[
                      "We will briefly review your current contractual framework and assess its suitability for your ongoing operations.",
                      "We will explore any new or expanded agreements that you may need for upcoming AI product launches or business initiatives.",
                    ].map((text, idx) => (
                      <ListItem key={idx} sx={{ py: 0.5 }}>
                        <ChevronRightIcon
                          sx={{ fontSize: 16, color: tertColor, mr: 1 }}
                        />
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* RIGHT SIDE: Reusable Contact Form */}
          <Grid item xs={12} md={6}>
            <Card
              elevation={3}
              sx={{
                borderRadius: 2,
                overflow: "hidden",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 8px 25px rgba(0,0,0,0.1)",
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: tertColor, color: "white" }}>
                <Typography variant="h5" fontWeight="medium" align="center">
                  Book Your Complimentary AI Legal Consultation
                </Typography>
              </Box>
              <CardContent>
                <Typography variant="body2" sx={{ mb: 3, textAlign: "center" }}>
                  Please complete the form below, and our team will reach out to
                  schedule a free phone consultation with one of our experienced
                  AI attorneys at your earliest convenience.
                </Typography>

                <EmbededContactForm
                  dropDownOptions={[
                    "General AI Agreements Inquiry",
                    "AI SaaS Tools & Applications",
                    "AI Model Licensing Agreements",
                    "AI Data & Training Agreements",
                    "AI Development Agreements",
                    "AI Consulting Agreements",
                    "AI Startup-Specific Agreements (Founder IP, Funding, etc.)",
                    "Other AI Legal Matter",
                  ]}
                  submitButtonLabel="Schedule My Free Phone Consultation"
                  disclaimerHTML="Please note that submitting this form does not create an attorney-client relationship. Any information shared will be kept confidential."
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* ======== FINAL DISCLAIMER ======== */}
      <Box
        textAlign="center"
        mt={6}
        pt={2}
        borderTop="1px solid #ccc"
        sx={{
          backgroundColor: "#f9f9f9",
          p: 2,
          borderRadius: 1,
        }}
      >
        <Typography variant="body2" fontStyle="italic" color="text.secondary">
          Disclaimer: Please do not share confidential details until we confirm
          our representation.
        </Typography>
      </Box>
    </Container>
  );
};

export default AiAgreements;
