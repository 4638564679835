import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CircularProgress,
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
} from "@mui/material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const KthSoftLic = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Software Licensing | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { headerTextColor } = baseConfig.siteBranding;
  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [compareBy, setCompareBy] = useState("permissive_use");

  useEffect(() => {
    const fetchLicenses = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrlKth}/kth-ip/foss`);
        setLicenses(response.data);
      } catch (err) {
        setError("Failed to fetch license data.");
        console.error("Error fetching licenses:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLicenses();
  }, []);

  const ComparisonOptions = [
    { value: "permissive_use", label: "Permissive Use" },
    { value: "attribution", label: "Attribution Required" },
    { value: "derivative_works", label: "Derivative Works" },
    { value: "patent_grant", label: "Patent Grant" },
    { value: "commercial_use", label: "Commercial Use" },
    { value: "copyleft", label: "Copyleft" },
  ];

  const handleCompareChange = (e) => {
    setCompareBy(e.target.value);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3rem",
          marginBottom: "3rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ my: 3 }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Grid>
      </Container>
    );
  }

  return (
    <Container className="link-no-decoration">
      <Card sx={{ mb: 4 }}>
        <CardHeader
          title={
            <Typography variant="h5">
              Free and Open Source Software Licenses
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="compare-by-label">
                  Compare Licenses By
                </InputLabel>
                <Select
                  labelId="compare-by-label"
                  id="compare-by"
                  value={compareBy}
                  label="Compare Licenses By"
                  onChange={handleCompareChange}
                >
                  {ComparisonOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>License</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>
                    {ComparisonOptions.find((opt) => opt.value === compareBy)
                      ?.label || "Feature"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licenses.map((license) => (
                  <TableRow key={license.slug} hover>
                    <TableCell>
                      <Link
                        to={`/software-licensing/${license.slug}`}
                        style={{ textDecoration: "none", color: "#1976d2" }}
                      >
                        {license.name}
                      </Link>
                    </TableCell>
                    <TableCell>{license.license_type}</TableCell>
                    <TableCell>{license.version}</TableCell>
                    <TableCell>
                      {typeof license[compareBy] === "boolean"
                        ? license[compareBy] === null
                          ? "No Data"
                          : license[compareBy]
                          ? "Yes"
                          : "No"
                        : license[compareBy] || "No Data"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Container>
  );
};

export default KthSoftLic;
