import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Chip,
  Link as MuiLink,
  Button,
  Card,
  CardContent,
  Divider,
  Paper,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ReusableTitle from "../common/ReusableTitle";
import GavelIcon from "@mui/icons-material/Gavel";
import { faTag } from "@fortawesome/pro-solid-svg-icons";
import { jumpToSectionAll, jumpToSectionStartup } from "./AiAgreements";

const KthAiEvaluation = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    secColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;

  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <ReusableTitle
          title="Evaluation of AI Applications"
          isMainHeading={true}
          siteBranding={baseConfig.siteBranding}
          icon={{ faIcon: faTag }}
        />

        <Box sx={{ mt: 2, mb: 4 }}>
          <ReusableTitle
            title="Overview of Kama Thuo, PLLC AI Evaluation Services"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Kama Thuo, PLLC provides comprehensive AI counseling services,
            including guidance on the legal and ethical sourcing of labeling
            services, and legal training on AI technologies and best practices.
            We also support clients in testing and evaluating AI outputs for
            bias and legal compliance. Our services also extend to ensuring AI
            systems adhere to privacy and data protection laws, offering
            strategic advice to navigate the complex regulatory landscape.
            Please{" "}
            <MuiLink component={RouterLink} to="/contact">
              contact us
            </MuiLink>{" "}
            to discuss your AI application evaluation needs.
          </Typography>

          <Grid container spacing={1} sx={{ mt: 2, ml: 3 }}>
            <Grid item>
              <Chip label="AI Bias" />
            </Grid>
            <Grid item>
              <Chip label="Regulatory Compliance" />
            </Grid>
            <Grid item>
              <Chip label="Rule 11 Investigations" />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2, mb: 4 }}>
          <ReusableTitle
            title="Legal Agreements for AI Applications, Models, and Systems"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            We deliver comprehensive services to draft, analyze, and optimize
            AI-related legal agreements, including SaaS contracts, model and
            data licensing agreements, IP licensing deals, technology transfer
            agreements, joint development contracts, and confidentiality and
            non-disclosure agreements.
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            We possess deep insights into critical legal terms and conditions
            essential in AI contracts, enabling our clients to proactively
            manage and minimize contractual risks. Explore specific legal
            clauses and provisions relevant to your AI transactions using our
            proprietary legal terms checker tool.
          </Typography>

          {/* AI Agreements and Terms CTA Container */}
          <Grid
            container
            spacing={3}
            alignItems="stretch"
            sx={{ mt: 3, ml: 2, mb: 4 }}
          >
            <Grid item xs={12} md={6}>
              <Card
                elevation={2}
                sx={{
                  borderRadius: 2,
                  overflow: "hidden",
                  height: "100%",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 8px 25px rgba(0,0,0,0.1)",
                  },
                }}
              >
                {/* Header with gradient background */}
                <Box
                  sx={{
                    background: `linear-gradient(135deg, ${tertColor} 0%, ${tertColor}CC 100%)`,
                    p: 2.5,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="white"
                    align="center"
                  >
                    Agreements & Terms for AI Applications
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      right: -20,
                      top: -20,
                      width: 100,
                      height: 100,
                      borderRadius: "50%",
                      backgroundColor: "rgba(255,255,255,0.1)",
                      zIndex: 0,
                    }}
                  />
                </Box>

                <CardContent sx={{ p: 0 }}>
                  {/* Description */}
                  <Box sx={{ p: 3, backgroundColor: "white" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 2,
                        lineHeight: 1.6,
                      }}
                    >
                      As a leading AI law firm, we provide custom-tailored
                      agreements and legal counsel for AI applications, SaaS
                      tools, data usage, licensing, and more.
                    </Typography>

                    {/* Divider with icon */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        my: 2,
                      }}
                    >
                      <Divider sx={{ flex: 1 }} />
                      <Box
                        sx={{
                          px: 2,
                          color: tertColor,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <GavelIcon />
                      </Box>
                      <Divider sx={{ flex: 1 }} />
                    </Box>

                    {/* AI Agreements Sections in a more visually appealing grid */}
                    <Grid container spacing={1.5}>
                      {[...jumpToSectionAll, ...jumpToSectionStartup].map(
                        (section) => (
                          <Grid item xs={12} sm={6} key={section.id}>
                            <Paper
                              elevation={0}
                              sx={{
                                p: 1.5,
                                borderRadius: 1,
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#f8f9fa",
                                border: "1px solid #e0e0e0",
                                transition: "all 0.2s ease",
                                "&:hover": {
                                  backgroundColor: `${tertColor}11`,
                                  borderColor: `${tertColor}66`,
                                  transform: "translateX(5px)",
                                },
                              }}
                            >
                              <Box sx={{ color: tertColor, mr: 1.5 }}>
                                {section.icon}
                              </Box>
                              <Typography
                                variant="subtitle2"
                                fontWeight="medium"
                              >
                                {section.label}
                              </Typography>
                            </Paper>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>

                  {/* CTA Buttons in a more attractive container */}
                  <Box
                    sx={{
                      p: 2.5,
                      backgroundColor: "#f8f9fa",
                      borderTop: "1px solid #e0e0e0",
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    {/* CTA Buttons */}

                    <Button
                      startIcon={<LaunchIcon />}
                      variant="contained"
                      sx={{
                        mt: 2,
                        ml: 3,
                        backgroundColor: tertColor,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: tertHoverColor,
                          color: "#fff",
                        },
                      }}
                      component={RouterLink}
                      to="/legal-agreements-for-ai"
                    >
                      Agreements for AI
                    </Button>
                    <Button
                      startIcon={<LaunchIcon />}
                      variant="contained"
                      sx={{
                        mt: 2,
                        ml: 3,
                        backgroundColor: tertColor,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: tertHoverColor,
                          color: "#fff",
                        },
                      }}
                      component={RouterLink}
                      to="/legal-terms-for-ai"
                    >
                      Legal Terms for AI
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2, mb: 2 }}>
          <ReusableTitle
            title="Regulatory & Industry Compliance"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Kama Thuo, PLLC assists clients in creating robust AI governance
            frameworks to ensure regulatory compliance and adherence to industry
            best practices. Our AI regulatory compliance services cover relevant
            global regulations (GDPR, CCPA, FTC guidelines) and industry
            standards specific to AI and data governance.
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            We offer tailored compliance assessments, strategic policy
            formulation, and practical recommendations for operationalizing AI
            governance frameworks. Identify and address compliance risks
            specific to your AI applications using our intuitive and detailed AI
            compliance checker tool.
          </Typography>
          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: tertColor,
              color: "#fff",
              "&:hover": { backgroundColor: tertHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/compliance-checker-for-ai"
          >
            Try Compliance Checker
          </Button>
          <Button
            startIcon={<LaunchIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 3,
              backgroundColor: priColor,
              color: "#fff",
              "&:hover": { backgroundColor: priHoverColor, color: "#fff" },
            }}
            component={RouterLink}
            to="/news-insights?category=ai-counseling"
          >
            News & Insights
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default KthAiEvaluation;
