import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link as MuiLink, Paper } from "@mui/material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const KthTM = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "Trademark Counseling | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  const { headerTextColor } = baseConfig.siteBranding;

  return (
    <Container maxWidth="lg" className="link-no-decoration">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ color: headerTextColor }}
        >
          Trademark Services
        </Typography>

        <Typography paragraph>
          Coming Soon TM Page - Amazon Brand Registry - Link to Amazon Patent
          Enforcement
        </Typography>
      </Paper>
    </Container>
  );
};

export default KthTM;
